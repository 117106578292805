import { useState } from 'react'
import { RHFShadowSettingsPropType } from './ShadowSettings'

import clsx from 'clsx'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { SaveButton } from '../../atoms/SaveButton/SaveButton'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { SnippetHeader } from '../../atoms/SnippetHeader/SnippetHeader'
import { RHFStateTabs } from '../../atoms/StateTabs/StateTabs'
import {
  FormContextProvider,
  useFormContext,
} from '../../form/context/FormContext'
import { getActiveStates } from '../helpers'
import { StateShadowFields } from './components/StateShadowFields'
import { getNormalizedRegisterValues } from './shadowHelpers'

export const DefaultShadowSettings = ({
  basePath,
  title = 'Shadow',
  className,
  stateTabs: {
    inheritedState,
    allowedInheritedStates = [],
    allowHover = false,
    allowActive = false,
    activeStateLabel = 'Active',
  } = {},
  shadowType,
}: RHFShadowSettingsPropType) => {
  const valuesPath = `${
    basePath ? basePath + '.data.responsiveConfig' : 'data.responsiveConfig'
  }`

  const metaPath = `${basePath ? basePath + '.meta' : 'meta'}`

  const {
    unregister,
    values,
    register,
    registerValues,
    placeholders,
    watch,
    onCreateSnippet,
    onDeleteSnippet,
    onDetachSnippet,
    onAssignSnippet,
    onIssueSnippet,
    onDiscardSnippet,
    parentSnippet,
    meta,
    allowSaveSnippet,
    disabledSnippetActions,
  } = useFormContext({
    snippetsType: 'SHADOW',
    metaPath: valuesPath,
    name: basePath,
  })

  const hasExistingValues = Object.keys(values).some((val) =>
    val.startsWith(`${valuesPath}`),
  )
  const [editSnippetMode, setEditSnippetMode] = useState(false)
  const isSnippet = Object.keys(meta).length > 0
  const hasSnippetUnpublishedChanges = meta.hasUnpublishedChanges
  const snippetId = meta.id

  const showSettings =
    (hasExistingValues && !isSnippet) ||
    (isSnippet && hasSnippetUnpublishedChanges) ||
    (isSnippet && editSnippetMode)

  const activeStates = getActiveStates({
    inheritedState,
    allowHover,
    allowActive,
  })

  const onAssignSnippetCallback = (
    id: string,
    options?: { shouldReplace?: boolean },
  ) => {
    onAssignSnippet({
      id,
      fieldName: valuesPath,
      parentSnippetId:
        (options?.shouldReplace ? undefined : snippetId) || parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  const onDetachSnippetCallback = () => {
    onDetachSnippet({
      id: snippetId,
      fieldName: valuesPath,
      parentSnippetId: parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  return (
    <FormContextProvider
      parentSnippet={{
        id: snippetId,
        path: meta.snippetPath,
        hasUnpublishedChanges: hasSnippetUnpublishedChanges,
      }}
    >
      <SegmentWrapper className={clsx('sc-rhf-shadow-settings', className)}>
        {isSnippet ? (
          <SnippetHeader
            snippetType={shadowType === 'box' ? 'BOX_SHADOW' : 'TEXT_SHADOW'}
            snippetName={meta.name}
            snippetId={snippetId}
            editSnippetMode={editSnippetMode}
            snippetHasOverrides={hasSnippetUnpublishedChanges}
            onAssignSnippet={onAssignSnippetCallback}
            detachButton={{
              show: !disabledSnippetActions?.includes('detach'),
              onClick: onDetachSnippetCallback,
            }}
            editButton={{
              show: !hasSnippetUnpublishedChanges,
              onClick: () => setEditSnippetMode(!editSnippetMode),
            }}
            removeButton={{
              show: true,
              onClick: () => unregister([`${valuesPath}`, `${metaPath}`]),
            }}
            resetButton={{
              show: hasSnippetUnpublishedChanges,
              onClick: () => {
                setEditSnippetMode(false)
                onDiscardSnippet({ id: snippetId })
              },
            }}
          />
        ) : (
          <HeaderSegment
            heading={title}
            addButton={{
              show: !hasExistingValues,
              onClick: () =>
                register(
                  getNormalizedRegisterValues({
                    valuesPath: valuesPath,
                    registerValues: registerValues,
                    availableStates: inheritedState
                      ? allowedInheritedStates
                      : activeStates,
                  }),
                ),
            }}
            removeButton={{
              show: hasExistingValues,
              onClick: () => unregister([`${valuesPath}`, `${metaPath}`]),
            }}
            snippetsDropdown={{
              show: true,
              snippetsType: shadowType === 'box' ? 'BOX_SHADOW' : 'TEXT_SHADOW',
              showSaveButton: hasExistingValues,
              onCreateSnippet: (snippetName) => {
                onCreateSnippet({
                  name: snippetName,
                  data: {
                    value: watch(valuesPath),
                  },
                  type: shadowType === 'box' ? 'BOX_SHADOW' : 'TEXT_SHADOW',
                  fieldName: valuesPath,
                  parentSnippetId: snippetId || parentSnippet?.id,
                  parentSnippetPath: parentSnippet?.path,
                })
              },
              onDeleteSnippet,
              onAssignSnippet: onAssignSnippetCallback,
            }}
          />
        )}
        {showSettings && (
          <div className="tw-flex tw-flex-col tw-gap-2">
            {!allowHover && !allowActive && !inheritedState && (
              <StateShadowFields
                state={'default'}
                valuesPath={valuesPath}
                shadowType={shadowType}
              />
            )}

            {inheritedState && (
              <StateShadowFields
                state={inheritedState}
                shadowType={shadowType}
                valuesPath={valuesPath}
              />
            )}
            {!inheritedState && (allowHover || allowActive) && (
              <RHFStateTabs
                disabledTabs={[
                  ...(!allowHover ? ['hover'] : []),
                  ...(!allowActive ? ['active'] : []),
                ]}
                activeStateLabel={activeStateLabel}
                defaultStateTabContent={
                  <StateShadowFields
                    state={'default'}
                    valuesPath={valuesPath}
                    shadowType={shadowType}
                  />
                }
                hoverStateTabContent={
                  <StateShadowFields
                    state={'hover'}
                    valuesPath={valuesPath}
                    shadowType={shadowType}
                  />
                }
                activeStateTabContent={
                  <StateShadowFields
                    state={'active'}
                    valuesPath={valuesPath}
                    shadowType={shadowType}
                  />
                }
              />
            )}
            {hasSnippetUnpublishedChanges && allowSaveSnippet && (
              <SaveButton
                onClick={() => {
                  onIssueSnippet({ id: snippetId, name: meta.name })
                  setEditSnippetMode(false)
                }}
              />
            )}
          </div>
        )}
      </SegmentWrapper>
    </FormContextProvider>
  )
}
