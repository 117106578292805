import { RHFNumericInput } from '../Input/NumericInput/NumericInput'
import { clsx } from 'clsx'
import { Icon } from '@sceneio/ui-icons'
import { CSSProperties, useEffect, useState } from 'react'
import { stringify } from '@sceneio/tools'
import { isSingleValueRadius } from './radiusHelpers'
import { StateType } from '../StateTabs/StateTabs'
import { BreakpointType } from '../BreakpointDropdown/BreakpointDropdown'
import * as Form from '@radix-ui/react-form'
import { useFormContext } from '../../form/context/FormContext'

export type RHFRadiusInputPropType = {
  label?: string

  className?: string
  hidden?: boolean
  breakpoint?: BreakpointType | 'default'
  state?: StateType
  options?: Readonly<string[]>
  valuesPath: string
  disabled?: boolean
  style?: CSSProperties
  isClearable?: boolean
}

export const RHFRadiusInput = ({
  style,
  label,
  valuesPath,
  state = 'default',
  breakpoint = 'default',
  className,
  isClearable = false,
}: RHFRadiusInputPropType) => {
  const { setValues, values, placeholders, forceBreakpoint } = useFormContext({
    name: valuesPath,
  })
  const [radiusCollapsed, setRadiusCollapsed] = useState(true)

  const path = valuesPath ? `${valuesPath}.data.` : 'data.'

  const metaDataTypeRegisterValues = {
    [`${valuesPath}.meta.dataType`]: 'border-style',
  }

  const setSingleValueRadius = (value: string | number | null) => {
    const topLeftRadiusName = `${path}${state}.--border-${breakpoint}-radius-top-left-${state}`
    const topRightRadiusName = `${path}${state}.--border-${breakpoint}-radius-top-right-${state}`
    const bottomLeftRadiusName = `${path}${state}.--border-${breakpoint}-radius-bottom-left-${state}`
    const bottomRightRadiusName = `${path}${state}.--border-${breakpoint}-radius-bottom-right-${state}`

    setValues({
      ...metaDataTypeRegisterValues,
      [topLeftRadiusName]: value,
      [topRightRadiusName]: value,
      [bottomLeftRadiusName]: value,
      [bottomRightRadiusName]: value,
    })
  }

  useEffect(() => {
    setRadiusCollapsed(
      isSingleValueRadius({
        values,
        state,
        breakpoint,
        path,
      }),
    )
  }, [stringify({ values })])

  const showCollapsingIcon =
    !forceBreakpoint || (forceBreakpoint && breakpoint !== 'default')

  return (
    <Form.Field
      className={clsx('sc-rhf-radius-input tw-container tw-text-xs', className)}
      style={style}
      name={valuesPath}
    >
      <Form.Label
        className={clsx('tw-bg-form-field-bg tw-rounded tw-relative tw-block', {
          'tw-bg-white': forceBreakpoint && breakpoint !== forceBreakpoint,
        })}
      >
        {label && (
          <div className="tw-flex-1 tw-min-w-0 tw-py-2 tw-px-3 tw-text-label-color">
            {label}
          </div>
        )}
        {radiusCollapsed && (
          <div className="tw-flex">
            <RHFNumericInput
              name={`${path}${state}.--border-${breakpoint}-radius`}
              label={<Icon provider="scene" icon="radius-tl" size="1rem" />}
              value={
                values[
                  `${path}${state}.--border-${breakpoint}-radius-top-left-${state}`
                ]
              }
              placeholder={
                placeholders[
                  `${path}${state}.--border-${breakpoint}-radius-top-left-${state}`
                ]
              }
              onChange={(val) => setSingleValueRadius(val)}
              defaultUnit="px"
              className="tw-flex-1"
              isClearable={isClearable}
            />
            {showCollapsingIcon && (
              <button
                type="button"
                className="tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center"
                onClick={() => setRadiusCollapsed(false)}
              >
                <Icon provider="phosphor" icon="CaretUpDown" />
              </button>
            )}
          </div>
        )}
        <div className={clsx('tw-flex', { 'tw-hidden': radiusCollapsed })}>
          <div className="tw-flex tw-flex-1">
            <RHFNumericInput
              name={`${path}${state}.--border-${breakpoint}-radius-top-left-${state}`}
              label={<Icon provider="scene" icon="radius-tl" size="1rem" />}
              isClearable={isClearable}
              onChange={(val) => {
                setValues({
                  ...metaDataTypeRegisterValues,
                  [`${path}${state}.--border-${breakpoint}-radius-top-left-${state}`]:
                    val,
                })
              }}
              defaultUnit="px"
            />
            <RHFNumericInput
              name={`${path}${state}.--border-${breakpoint}-radius-top-right-${state}`}
              label={<Icon provider="scene" icon="radius-tr" size="1rem" />}
              isClearable={isClearable}
              onChange={(val) => {
                setValues({
                  ...metaDataTypeRegisterValues,
                  [`${path}${state}.--border-${breakpoint}-radius-top-right-${state}`]:
                    val,
                })
              }}
              defaultUnit="px"
            />
            <RHFNumericInput
              name={`${path}${state}.--border-${breakpoint}-radius-bottom-left-${state}`}
              label={<Icon provider="scene" icon="radius-bl" size="1rem" />}
              isClearable={isClearable}
              onChange={(val) => {
                setValues({
                  ...metaDataTypeRegisterValues,
                  [`${path}${state}.--border-${breakpoint}-radius-bottom-left-${state}`]:
                    val,
                })
              }}
              defaultUnit="px"
            />
            <RHFNumericInput
              name={`${path}${state}.--border-${breakpoint}-radius-bottom-right-${state}`}
              label={<Icon provider="scene" icon="radius-br" size="1rem" />}
              isClearable={isClearable}
              onChange={(val) => {
                setValues({
                  ...metaDataTypeRegisterValues,
                  [`${path}${state}.--border-${breakpoint}-radius-bottom-right-${state}`]:
                    val,
                })
              }}
              defaultUnit="px"
            />
          </div>
          {showCollapsingIcon && (
            <button
              type="button"
              className="tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center aria-disabled:tw-opacity-50"
              onClick={() => setRadiusCollapsed(true)}
              aria-disabled={
                !isSingleValueRadius({
                  values: values,
                  state,
                  breakpoint,
                  valuesPath,
                })
              }
              disabled={
                !isSingleValueRadius({
                  values: values,
                  state,
                  breakpoint,
                  valuesPath,
                })
              }
            >
              <Icon provider="scene" icon="caret-collapse" size="1rem" />
            </button>
          )}
        </div>
      </Form.Label>
    </Form.Field>
  )
}
