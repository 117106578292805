type StringOrNumber = string | number

function diff<V extends StringOrNumber>(a: V[], b: V[]) {
  return a.filter((v) => !b.includes(v))
}

export function arraysSymetricDifference<V extends StringOrNumber>(
  first: V[],
  ...rest: V[][]
) {
  return rest.reduce((acc, x) => [...diff(acc, x), ...diff(x, acc)], first)
}
