import { useMemo } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { stringify } from '@sceneio/tools/lib/stringify'
import { RawModalProps, ModalButtonType } from '../modalTypes'
import clsx from 'clsx'
import styles from './modal.scss?inline'

export function RawModal({
  show = false,
  children,
  title = null,
  onShow = () => {},
  onClose = () => {},
  onAgree = () => {},
  onCancel = () => {},
  showModalHeader = true,
  hideCancelButton = false,
  hideAgreeButton = false,
  cancelButtonConfig = {},
  agreeButtonConfig = {},
  hideButtons = false,
  size,
  scrollable = true,
  centered = false,
  className,
  dialogClassName,
  backdropClassName,
}: RawModalProps) {
  const cancelButtonProps: ModalButtonType = useMemo(
    () => ({
      variant: 'outline-secondary',
      children: 'Cancel',
      ...cancelButtonConfig,
    }),
    [stringify(cancelButtonConfig)],
  )
  const agreeButtonProps: ModalButtonType = useMemo(
    () => ({
      children: 'I Agree',
      ...agreeButtonConfig,
    }),
    [stringify(agreeButtonConfig)],
  )

  const onCustomAgree = () => {
    onAgree()
    onClose()
  }

  const onCustomCancel = () => {
    onCancel()
    onClose()
  }

  return (
    <>
      <style>{styles}</style>
      <Modal
        size={size}
        centered={centered}
        scrollable={scrollable}
        show={show}
        onShow={onShow}
        onHide={onClose}
        className={clsx('raw-modal', className)}
        dialogClassName={dialogClassName}
        backdropClassName={backdropClassName}
      >
        {showModalHeader && (
          <Modal.Header closeButton>
            {title && <Modal.Title as="h6">{title}</Modal.Title>}
          </Modal.Header>
        )}
        <Modal.Body>
          {/* BUGFIX - Issue when modal body contains nested react-select component.
          If select menu is opened and focused, after close modal interaction (Click on "X" button) modal is not properly unmounted and still rendered in DOM with 0 opacity.
          This will block user interaction with other underlaying elements within a page.
          */}
          {show && (
            <>
              {children || <span className="text-muted">Body placeholder</span>}
            </>
          )}
        </Modal.Body>
        {hideButtons === false && (
          <Modal.Footer>
            {!hideCancelButton && (
              <Button {...cancelButtonProps} onClick={onCustomCancel} />
            )}
            {!hideAgreeButton && (
              <Button {...agreeButtonProps} onClick={onCustomAgree} />
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}
