import { insert } from 'ramda'
import { nestedIdsRandomizer } from '@sceneio/tools'

type CommonItemsFactoryType = {
  itemsArr: any[]
  itemsId: string
  index: number
}
type FactoryReturnType = () => {
  id: string
  value: any
}

type AddItemFactory = CommonItemsFactoryType & {
  itemFactory: () => any
}
export function addItemFactory({
  itemsArr,
  itemsId,
  itemFactory,
  index,
}: AddItemFactory): FactoryReturnType {
  return function () {
    return {
      id: itemsId,
      value: insert(index + 1, nestedIdsRandomizer(itemsArr[index]), itemsArr),
    }
  }
}

type RemoveItemFactory = CommonItemsFactoryType

export function removeItemFactory({
  itemsArr,
  itemsId,
  index,
}: RemoveItemFactory): FactoryReturnType {
  return function () {
    return {
      id: itemsId,
      value: itemsArr.filter((_, i) => i !== index),
    }
  }
}

type EditorItemsControlsFactory = AddItemFactory & RemoveItemFactory

type EditorItemsControlFactoryReturn = {
  itemAdd: FactoryReturnType
  itemRemove: FactoryReturnType
  disableItemAdd: boolean
  disableItemRemove: boolean
}

export function editorItemsControlsFactory({
  itemsId,
  itemsArr,
  itemFactory,
  index,
}: EditorItemsControlsFactory): EditorItemsControlFactoryReturn {
  return {
    itemAdd: addItemFactory({ itemsId, itemsArr, itemFactory, index }),
    itemRemove: removeItemFactory({ itemsId, itemsArr, index }),
    disableItemAdd: false,
    disableItemRemove: itemsArr.length <= 1,
  }
}
