import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import { createReduxEnhancer } from '@sentry/react'
import storage from 'redux-persist/lib/storage'
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import rootReducerFactory from './redux/reducers'
import { localHistoryMiddleware } from './redux/middlewares/localHistoryMiddleware'
import { UpdateBlockCommand } from '@sceneio/content-store/lib/localHistoryCommands/UpdateBlockCommand'
import { contentSlice } from '@sceneio/content-store/lib/features/content/contentSlice'
import { editorSlice } from '@sceneio/content-store/lib/features/editor/editorSlice'
import { commentsSlice } from '@sceneio/content-store/lib/features/comments/commentsSlice'
import { serverSlice } from '@sceneio/content-store/lib/features/server/serverSlice'
import { dashboardSlice } from '@sceneio/content-store/lib/features/dashboard/dashboardSlice'
import { snippetsSlice } from '@sceneio/content-store/lib/features/snippets/snippetsSlice'
import { contentModulesSlice } from '@sceneio/content-store/lib/features/contentModules/contentModulesSlice'
import { whiteboardSlice } from '@sceneio/content-store/lib/features/whiteboard/whiteboardSlice'
import { projectSlice } from '@sceneio/content-store/lib/features/project/projectSlice'
import { persistSlice } from '@sceneio/content-store/lib/features/persist/persistSlice'
import { assetsSlice } from '@sceneio/content-store/lib/features/assets/assetsSlice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'ui', 'persist'],
}

const persistedReducer = persistReducer(
  persistConfig,
  rootReducerFactory({
    content: contentSlice.reducer,
    assets: assetsSlice.reducer,
    editor: editorSlice.reducer,
    comments: commentsSlice.reducer,
    server: serverSlice.reducer,
    dashboard: dashboardSlice.reducer,
    snippets: snippetsSlice.reducer,
    contentModules: contentModulesSlice.reducer,
    whiteboard: whiteboardSlice.reducer,
    project: projectSlice.reducer,
    persist: persistSlice.reducer,
  }),
)

const loggerMiddleware = createLogger({
  collapsed: true,
  duration: true,
})
const middlewares = []

const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
})

if (import.meta.env.DEV) {
  middlewares.push(loggerMiddleware)
}

export const store = configureStore({
  reducer: persistedReducer,
  // TODO temporarily turned off our middlewares, perhaps the loggerMiddleware is already in redux toolkit default middlewares
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          'WhiteboardContentEntitiesUpdateMetaCommand',
          'WhiteboardContentEntitiesDeleteCommand',
          'CreateContentModulesCommand',
          'DeleteContentModulesCommand',
          'UpdateContentModulesCommand',
          'SetContentBlocksAsRenderableCommand',
          'SetContentBlocksAsNonRenderableCommand',
          'UpdateBlockCommand',
          'UpdateStylesCommand',
          'AddBlockCommand',
          'CreateRawSnippetCommand',
          'DeleteBlockCommand',
          'MoveBlockCommand',
          'UpdateReusableBlockCommand',
          'DuplicateBlockCommand',
        ],
      },
    }).concat(localHistoryMiddleware)
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers.concat(sentryReduxEnhancer)
  },
  devTools: { actionsDenylist: ['editor/setBlockDynamization'] },
})

export const persistor = persistStore(store)

export const dispatchType = <P>(type: string, payload?: P): void => {
  store.dispatch({
    type,
    payload,
  })
}

export type RootState = ReturnType<typeof store.getState>
