// ACL
export const ACL = {
  ACL_FETCH_SUCCESS: 'ACL_FETCH_SUCCESS',
  ACL_FETCH_FAIL: 'ACL_FETCH_FAIL',
}

// USER
export const USER = {
  LOGIN_SUCCESS: 'USER:LOGIN:SUCCESS',
  LOGIN_FAIL: 'USER:LOGIN:FAIL',
  LOGOUT: 'USER:LOGOUT',
  SELECT_FOLDER: 'USER:SELECT:FOLDER',
  SELECT_LANGUAGE_CMS: 'USER:SELECT:LANGUAGE:CMS',
  UPDATE: 'USER:UPDATE',
}
// PAGE
export const PAGE = {
  SET_META: 'PAGE:SET:META',
}
// UI
export const UI = {
  SWITCH_HEADER: 'UI:SWITCH:FIX:HEADER',
  SET_LOADING_START: 'UI:SET:LOADING:START',
  SET_LOADING_STOP: 'UI:SET:LOADING:STOP',
}
// SYSTEM
export const SYSTEM = {
  WEBSITE_LANGUAGES_FETCH_SUCCESS: 'SYSTEM:FETCH:WEBSITE_LANGUAGES:SUCCESS',
  WEBSITE_LANGUAGES_FETCH_FAIL: 'SYSTEM:FETCH:WEBSITE_LANGUAGES:FAIL',
}
// API
export const API = {}
