import React from 'react'
import clsx from 'clsx'
import type { Hero5Block } from '@sceneio/schemas/lib/types'
import Block from '../../../components/Block/Block'
import Media from '../../../components/Media/Media'
import Text from '../../../components/Text/Text'
import Image from '../../../components/Image/Image'
import Button from '../../../components/Button/Button'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { Container } from '../../../components/Container/Container'
import { hero5DefaultConfig, buttonSampleFactory } from './hero5Meta'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'

function Hero5({
  id,
  components: {
    block,
    logo,
    subheading,
    text,
    heading,
    media,
    author,
    buttons,
  },
  editor,
  preview,
}: Hero5Block = hero5DefaultConfig) {
  const { className, ...restBlockProps } = block || {}

  const hero5Class = clsx(`sc-hero-5`, className)

  const showButtons = Boolean(buttons?.items?.length > 0 && buttons?.show)

  return (
    <Block className={hero5Class} id={id} editor={editor} {...restBlockProps}>
      <Image
        {...logo}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'logo',
        }}
      />
      <Text
        {...subheading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'subheading',
          wysiwyg: {
            type: 'HEADING',
          },
        }}
        cid={resolveComponentId(id, 'subheading')}
      />
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
          wysiwyg: {
            type: 'HEADING',
          },
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <Text
        {...author}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'author',
        }}
        cid={resolveComponentId(id, 'author')}
      />
      <Container
        className={'sc-hero-5__buttons bui-flex-wrap bui-w-100'}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'buttons',
        }}
        {...buttons}
        name="componentArray"
        show={showButtons}
      >
        {buttons?.items?.map(
          (
            {
              id: buttonId,
              childrenWrapProps = {},
              className: buttonClassname,
              ...restButtonProps
            },
            index,
          ) => (
            <Button
              className={clsx('sc-hero-5__button', buttonClassname)}
              childrenWrapProps={{
                ...childrenWrapProps,
              }}
              key={buttonId || index}
              cid={resolveComponentId(id, `button-${index}`)}
              {...restButtonProps}
              editor={{
                ...editor?.component,
                blockId: id,
                id: `buttons.items[${index}]`,
                wysiwyg: {
                  type: 'ITEMS',
                  ...editorItemsControlsFactory({
                    itemsArr: buttons.items || [],
                    itemsId: `buttons.items`,
                    itemFactory: buttonSampleFactory,
                    index: index,
                  }),
                },
              }}
            />
          ),
        )}
      </Container>
      <Media
        {...media}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'media',
        }}
        components={{
          ...media.components,
          caption: {
            show: false,
          },
        }}
        className={clsx('sc-hero-5__media')}
        cid={resolveComponentId(id, 'media')}
        preview={preview}
      />
    </Block>
  )
}

export const hero5Plugins: PluginsType = {
  js: ['video', 'animation', 'social'],
  css: [],
}
export default Hero5
