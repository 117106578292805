export const data = {
  "aclallowedresourcesquerydocument": {
    "hash": "a82e3cb756b802e5a305700065cddbfa12d4cdf2f0b6940cccfe0cb00ead21b3:3.4.2",
    "operation": "query"
  },
  "aclrolesresourcesquerydocument": {
    "hash": "2659ede291deccf35495fed40af0b7a9d63d685d5adcbc079a2150dc3253ab9b:3.4.2",
    "operation": "query"
  },
  "acluserpermissionsquerydocument": {
    "hash": "81f91b45511bb22d4b0147b2620732b49c003e451e208adce7f879cd2790ba5f:3.4.2",
    "operation": "query"
  },
  "anonymousquerydocument": {
    "hash": "0ecfe1a851504890cb74bca9f2058a5467f528f7c3438595a7c6eb697f4d1fec:3.4.2",
    "operation": "query"
  },
  "assetdeletemutationdocument": {
    "hash": "cbba3c0ee28b6243571bf0ec8e1cd145a8db91af7490557694689ee6cac910e8:3.4.2",
    "operation": "mutation"
  },
  "assetrenamemutationdocument": {
    "hash": "565fee0a23f9e159f922016d5c8823e8f1852498da83b1160d8e994fa7dafe8d:3.4.2",
    "operation": "mutation"
  },
  "assetsquerydocument": {
    "hash": "0bf188c759b0df0ee53d3eb6ea9b5a669213775831d44f017dffb08f78338f48:3.4.2",
    "operation": "query"
  },
  "assignreusablecontentblockdocument": {
    "hash": "2e391251e22aa471d021466d2688058a6f80ba10f81902f443fbe6ae0ea7bc0f:3.4.2",
    "operation": "mutation"
  },
  "attributespathassignmentquerydocument": {
    "hash": "cb93b0f1bb3f0aa5a712e102d9c598f8412db63a5bbed2b1b7e394679cd44845:3.4.2",
    "operation": "query"
  },
  "changecontentpublishstatemutationdocument": {
    "hash": "cd415163eda0d08daf6a4a281025d27090c4fd478c8313daebf6de875f49b9a9:3.4.2",
    "operation": "mutation"
  },
  "checkdomainavailabilitydocument": {
    "hash": "0a76043508fc4b892a333faea7274dd3cb78e9cd07deb00c5dbbe140ac691560:3.4.2",
    "operation": "query"
  },
  "commandsubscriptiondocument": {
    "hash": "7d16543a288027828e236446f0c8a6c0732ad21812c0e8f6ea95bacc2852bd22:3.4.2",
    "operation": "subscription"
  },
  "commentcreatedsubscriptiondocument": {
    "hash": "ddb1e5f6680e7a2192552964681cb7318d06a5831f26d7f77a84161287c2787c:3.4.2",
    "operation": "subscription"
  },
  "commentcreatemutationdocument": {
    "hash": "cce3fb208bde461df2a2bbe4242d2229bec26f43d2c6f2804726c4c5edc4b3cf:3.4.2",
    "operation": "mutation"
  },
  "commentdeletedsubscriptiondocument": {
    "hash": "98d4fb01345fe4d36dcf884e0a310f5b1ccf7c53f162e10b0815d6732fa75908:3.4.2",
    "operation": "subscription"
  },
  "commentrepliedsubscriptiondocument": {
    "hash": "3b2a295510d9154c7a2d0bf56527184f8ce13603ff478e2f88e8962aaa19700b:3.4.2",
    "operation": "subscription"
  },
  "commentreplytomutationdocument": {
    "hash": "286106aa6aa770f53be9a952d73faabd7e1e359b699bb9ac8e5e86e8fda6c6d9:3.4.2",
    "operation": "mutation"
  },
  "commentresolvedsubscriptiondocument": {
    "hash": "24fac09246b2e83eab137eb199508116a50ec5e393a1be8f4829a9b3ca67091b:3.4.2",
    "operation": "subscription"
  },
  "commentsquerydocument": {
    "hash": "0d56e618283f4898b8cc8446b1da02ddfd65ac584d919c3413b25febad35463d:3.4.2",
    "operation": "query"
  },
  "commentupdatedsubscriptiondocument": {
    "hash": "44dd3c881d9929e9b26620f862734466b47eb74aec30de14b524d47bb12bdab1:3.4.2",
    "operation": "subscription"
  },
  "commentupdatemutationdocument": {
    "hash": "ed1684ce5e2243936945a58a1be94746b594aa284605db772c72bdde66e94f38:3.4.2",
    "operation": "mutation"
  },
  "contentauditquerydocument": {
    "hash": "ed5e9fd81f73155c10478868a871b30c9be99c9336cd95a096a4781ebbf6959d:3.4.2",
    "operation": "query"
  },
  "contentblockaddmutationdocument": {
    "hash": "03434da4ebb8263a156ba085a3ca2c455ec5dcd20f77b487636efcc2f762742a:3.4.2",
    "operation": "mutation"
  },
  "contentblockdeletemutationdocument": {
    "hash": "98f472272c3f22433828ef34e5a35acbb490a45bdd90461c77e587eb455dda96:3.4.2",
    "operation": "mutation"
  },
  "contentblockduplicatemutationdocument": {
    "hash": "bd1658a900f8da0c58135f8285141b2af7dd5ff5987ed745fb8771cc0b64ddd2:3.4.2",
    "operation": "mutation"
  },
  "contentblockorderupdatemutationdocument": {
    "hash": "c92746aa1c80143e95955e0c1c6e806e19c36cf87b3ab65cc30c0ee511175c9f:3.4.2",
    "operation": "mutation"
  },
  "contentblocksaddmutationdocument": {
    "hash": "6f4bbe7b1a76428adeb71ae9f37855d1f6504240a93da5ff2d044dd028a88c21:3.4.2",
    "operation": "mutation"
  },
  "contentblockscreatedsubscriptiondocument": {
    "hash": "8a18206b768897c4f4da2a90ad8339a469575babd331db2e95a158104d5f5575:3.4.2",
    "operation": "subscription"
  },
  "contentblocksdeletedsubscriptiondocument": {
    "hash": "8a9141e42e246d7b5eb1c77066bfca1917bf03ae7c4058a506d49d0961196402:3.4.2",
    "operation": "subscription"
  },
  "contentblocksdeletemutationdocument": {
    "hash": "2d244adf4171a3b091700f94ae2746b8e47ca8632a7b2310030ad717645ec7cd:3.4.2",
    "operation": "mutation"
  },
  "contentblocksinteractionchangedsubscriptiondocument": {
    "hash": "e22c32f3dfdfa2b936f85a76844d925e2b49f5a1c0f0b7b6782f4227a2a99f70:3.4.2",
    "operation": "subscription"
  },
  "contentblockssortedsubscriptiondocument": {
    "hash": "4f6aabf7d2c0821b04a8133072222c64017a57978237d6c31f0e9f60bb26cdf6:3.4.2",
    "operation": "subscription"
  },
  "contentblocksupdatedsubscriptiondocument": {
    "hash": "7d4f603c972f7dd909ce46bf4552686b6cbe7aa47bad4c689f05113a6c5ae5ca:3.4.2",
    "operation": "subscription"
  },
  "contentblocksupdatemutationdocument": {
    "hash": "7c66e565413ce69b773181c36acc2b76917fd2940f62ff16e58557846f3f0dcc:3.4.2",
    "operation": "mutation"
  },
  "contentblockupdatemutationdocument": {
    "hash": "4182b7a2e998a20e7310c873817ba88e3240765a4bd269107e93a02949786697:3.4.2",
    "operation": "mutation"
  },
  "contentcreatevariantmutationdocument": {
    "hash": "1d92db6dc8f147f333d0b473100e4d02dd72c0b30c75743f5946bb17fd2b0a6a:3.4.2",
    "operation": "mutation"
  },
  "contentdataquerydocument": {
    "hash": "8ef07389dda7222876d89f98f93d0f211bd94328e4568a4a37b176e97a72588c:3.4.2",
    "operation": "query"
  },
  "contentdeletedsubscriptiondocument": {
    "hash": "8d2b3f9a6e1e6fe6f5f1a096306a87feb8a9662f7ed2b134c7b07c1ac1c55bd2:3.4.2",
    "operation": "subscription"
  },
  "contentdeletemutationdocument": {
    "hash": "c763a3fddd0a50bc669165b62f860861ed54cc7235aeae95b5a3787319c024f8:3.4.2",
    "operation": "mutation"
  },
  "contententitiesmetaupdatedsubscriptiondocument": {
    "hash": "95a4e08f0ba47747c113dad04780deede309804182a1dd8ca86f27d41d6300fa:3.4.2",
    "operation": "subscription"
  },
  "contentlistquerydocument": {
    "hash": "43e26830d8e5616bb4a74290868058c9d87b5216c7a73effb0ca98b5dd70212f:3.4.2",
    "operation": "query"
  },
  "contentmergevariantmutationdocument": {
    "hash": "bf6602936e265e6ef66e9dfea08909721a556b70d409ee4e5b2e19cbff6a21ce:3.4.2",
    "operation": "mutation"
  },
  "contentmodulescreatedsubscriptiondocument": {
    "hash": "0b4a09ea0df2eff2cfb8297c6e25fcbfc6db2dd0f9fdf973bcbf31880b9b88d2:3.4.2",
    "operation": "subscription"
  },
  "contentmodulescreatemutationdocument": {
    "hash": "13ad079813c0df32e656d7ec0f21fe5422e35e8fbf1992e236a8671e8dcefb77:3.4.2",
    "operation": "mutation"
  },
  "contentmodulesdeletedsubscriptiondocument": {
    "hash": "16b09b8444e9b6a1710c78ebe6a968cae911fc2650addb5afb34e0add3f73117:3.4.2",
    "operation": "subscription"
  },
  "contentmodulesdeletemutationdocument": {
    "hash": "a9677d89cf919621d84498e474fda4408319e56f3e531a8266c6be282a4778bd:3.4.2",
    "operation": "mutation"
  },
  "contentmodulesquerydocument": {
    "hash": "199ecf9de66e9f906024b72abe09c99d118a54c51fdb1db3bc6ac18a137dc7d7:3.4.2",
    "operation": "query"
  },
  "contentmodulesupdatedsubscriptiondocument": {
    "hash": "55f3b9de4101cb45886dc5a747d8f5cdde60ea3a4b774082e1df27fc51cb3087:3.4.2",
    "operation": "subscription"
  },
  "contentmodulesupdatemutationdocument": {
    "hash": "5f0de2d1ab79a0c089ba91c70d7b12df469ba5e940bc9dc9a6991be3cb2eff78:3.4.2",
    "operation": "mutation"
  },
  "contentonlineusersquerydocument": {
    "hash": "ff5964811cbc52ee9131761456e23c6388895a5e850aca5130d39a01241bb846:3.4.2",
    "operation": "query"
  },
  "contentonlineuserssubscriptiondocument": {
    "hash": "0f5eaaab73243a965f19c35b460daa74dfb0bcb8cae6850e601c527a57772635:3.4.2",
    "operation": "subscription"
  },
  "contentoverviewquerydocument": {
    "hash": "82e2ef0d591639a47bf3a3820f7e6a49ac9b6ee3647be4e608129b9e794a3853:3.4.2",
    "operation": "query"
  },
  "contentpublishedlistquerydocument": {
    "hash": "a314b72ffa935e0be8ed114e40d811237fba05959026f80e5e92b1d8ea097ee5:3.4.2",
    "operation": "query"
  },
  "contentsquerydocument": {
    "hash": "bae046f91895aab6545ee0013aa958eddebb78defbd06cddeb6e0af193e06902:3.4.2",
    "operation": "query"
  },
  "contentupdatedatquerydocument": {
    "hash": "58e787a53fce0f4a79e39f2d882cc3106262230617b8f6ff651a3e4297762d03:3.4.2",
    "operation": "query"
  },
  "contentupdatedsubscriptiondocument": {
    "hash": "25ef9a06d874b47d4d154681f47f11337764b481dff1d7a307911330a7be0bb6:3.4.2",
    "operation": "subscription"
  },
  "contentupdatemutationdocument": {
    "hash": "9c072a5495a68ebd80bc42c4d0c5a58b6bc32598353a124f2f76ea65d3337d0f:3.4.2",
    "operation": "mutation"
  },
  "countrycodesquerydocument": {
    "hash": "00e9cbcfd5c59e237e8b311368c1c0552642db7a8ac8b1d56f53fe8049012024:3.4.2",
    "operation": "query"
  },
  "createcontentversionmutationdocument": {
    "hash": "17f72bc721c1f1864dcd65abe194a1f402335b0147421a2e18aa65902ae9ba3f:3.4.2",
    "operation": "mutation"
  },
  "createpreviewbypathmutationdocument": {
    "hash": "bff2c623e5d9b8af38f7a471112ba5fb4047c2bc3d927f091029c7309b304e28:3.4.2",
    "operation": "mutation"
  },
  "createprojectuserwithpermissionsdocument": {
    "hash": "474891f49827807b1daf93bf1dbb6391db9fd06ec7f5ace899bd67523cbfe336:3.4.2",
    "operation": "mutation"
  },
  "createsnippetmutationdocument": {
    "hash": "3f07522ad9850d98d814f06e18285bf6eba78e79c0b6e07a2e34430de2c01110:3.4.2",
    "operation": "mutation"
  },
  "deletecommentmutationdocument": {
    "hash": "68f464b2a9ed94ef5e314963a15399dd0a3241447be632dea69a3b11493c6ded:3.4.2",
    "operation": "mutation"
  },
  "deletereusablecontentblockdocument": {
    "hash": "8b7c29d910360ee313cee4bfdea5f75cba912fbf60300486911c0fdb48cd962c:3.4.2",
    "operation": "mutation"
  },
  "deletesnippetmutationdocument": {
    "hash": "0c6b3d7d0dd60df89db0bd5ef6a432cb7a7a72692099d6250fb9bd55086adbbd:3.4.2",
    "operation": "mutation"
  },
  "dependencyforquerydocument": {
    "hash": "cdbfc9398f7ae502b1dda81c8f36b026b7d088d9a8b56780cb6c9c303b5d1da5:3.4.2",
    "operation": "query"
  },
  "deploymentquerydocument": {
    "hash": "6ea57852f554c7e41626f26855df460ae07c2f1f13e6000e027e553c74d4497e:3.4.2",
    "operation": "query"
  },
  "devtoolsquerydocument": {
    "hash": "f9789b20adc5b7f5ad47e62f4eb7bf384d13e9ea74e6d0ab017553ee9c32c493:3.4.2",
    "operation": "query"
  },
  "discardsnippetmutationdocument": {
    "hash": "46af36d6d866cae9b1e23035be5c006515ee15200cf69129e5d389af861987b4:3.4.2",
    "operation": "mutation"
  },
  "domainenvironmentquerydocument": {
    "hash": "6f7096fb6c15fb348c03e282701431e815b514b39f3c918e112fb966c1c2193b:3.4.2",
    "operation": "query"
  },
  "domainsdetailquerydocument": {
    "hash": "c7ac900e02d7abe488748ae0e01ddfd78a38f998c721d020d6dc1a52572aab4d:3.4.2",
    "operation": "query"
  },
  "dynamiccontentparentpathsquerydocument": {
    "hash": "7af70620a28ab4733accaed54d06f6df070098ad00e456f77e3da3c12547317d:3.4.2",
    "operation": "query"
  },
  "dynamiccontentquerydocument": {
    "hash": "8f64c0a247c8ee8b4628683f750ded56049395d08c270e7e2f24a31300d9068e:3.4.2",
    "operation": "query"
  },
  "dynamicpathsbyrootidsdocument": {
    "hash": "53dce953b2962dea14a79424611291353ab6d73b4c9784f04949e1c59ca0716c:3.4.2",
    "operation": "query"
  },
  "executedeploymentmutationdocument": {
    "hash": "f36088605be4f413ecf46f43bfe87c79bab4a6adea39e798a97043243f86f18b:3.4.2",
    "operation": "mutation"
  },
  "fontcreatedsubscriptiondocument": {
    "hash": "f2dcfbc6dab478d485c38e56535f4f4d8bc4d4cc0a313035ad7ff4ce9302a862:3.4.2",
    "operation": "subscription"
  },
  "fontdeletedsubscriptiondocument": {
    "hash": "f60e8413d39c743352cca9fa3b73e5d748ceb16307ff8a9aa3872813adbc13f2:3.4.2",
    "operation": "subscription"
  },
  "fontscdnintegrityquerydocument": {
    "hash": "310d926a234084e1f37a79573591354ec06fad62a33c7ae69befd0af6880a0af:3.4.2",
    "operation": "query"
  },
  "fontstylebyidquerydocument": {
    "hash": "8504e6c33068bb3019c05cfaea3ea0740834bb84b675d5e7d2e731e03f57cb44:3.4.2",
    "operation": "query"
  },
  "fontstylecreatemutationdocument": {
    "hash": "39697396eebc90c4c6c892823aee93544795e81fb49f10f8b70b1bc348962c46:3.4.2",
    "operation": "mutation"
  },
  "fontstyledeletemutationdocument": {
    "hash": "81841a75de61e34c544b032cb048ae4307173694e3cc5bbae4817b34641519be:3.4.2",
    "operation": "mutation"
  },
  "fontstylesquerydocument": {
    "hash": "7276fefa5f424b7fb03e13251f5e52b397276d9c644330872731696dbded5d51:3.4.2",
    "operation": "query"
  },
  "fontstyleupdatemutationdocument": {
    "hash": "c00fd064ee76c17c7c2c996e0dc00eab816232498bc8d7a43f36672503979189:3.4.2",
    "operation": "mutation"
  },
  "fontupdatedsubscriptiondocument": {
    "hash": "d697493f6196ad05df3195c22861517d440f8bd3b2599928c5c9a6630f1f81f3:3.4.2",
    "operation": "subscription"
  },
  "historyquerydocument": {
    "hash": "9f13441211d302db399b5bace4b07d089c856d6f141a237b4b5fe82205f31daa:3.4.2",
    "operation": "query"
  },
  "issuedsnippetdependencyforquerydocument": {
    "hash": "3463afada38fc39709bf0a6de80c7a207a7d2eeabb1bef62316b527946907305:3.4.2",
    "operation": "query"
  },
  "issuesnippetmutationdocument": {
    "hash": "64aa6d4c3eaf142fca776813b1f009e167ddbecfa2717d13699a8e69376e2b36:3.4.2",
    "operation": "mutation"
  },
  "keepalivemutationdocument": {
    "hash": "358945059981997bb822624d46d62385028c47130e7304c6fc16b0f8e596c020:3.4.2",
    "operation": "mutation"
  },
  "languagesquerydocument": {
    "hash": "e5f6e4fe73a9738943c7dd081cf315adacb94bb7d2b6062c4cb5803ec3b56d85:3.4.2",
    "operation": "query"
  },
  "loginemailmutationdocument": {
    "hash": "86bc11baea24855fe4017239e039916b63e68bb6d011fdfff3dca2cab1884d67:3.4.2",
    "operation": "mutation"
  },
  "logingooglemutationdocument": {
    "hash": "8989a27f77f51de660e8089a61e2a1d367f53082dcbafaa79d968866a5688195:3.4.2",
    "operation": "mutation"
  },
  "logintokenmutationdocument": {
    "hash": "6e06fa0b8998297f24f9c06ae1ea33a5ca76e5e2db810d44d836fe3653fd211d:3.4.2",
    "operation": "mutation"
  },
  "makehomepagedocument": {
    "hash": "83fefd1fd3f3f8a44f3bb22da0bd81275bf624c1d7fb728f4d2e95476fc62cee:3.4.2",
    "operation": "mutation"
  },
  "menucreatemutationdocument": {
    "hash": "4d2ae70972877ec3aecb9015e8c765d128e6e3a641dbd34e51ac96e8671595fe:3.4.2",
    "operation": "mutation"
  },
  "menuupdatemutationdocument": {
    "hash": "e4589993644e725d51a2982f94aad3ccda2a866317bdb4304c61f3563dc07c2b:3.4.2",
    "operation": "mutation"
  },
  "mergereusablecontentblockdraftdocument": {
    "hash": "4b5dd73b0f6fea66ba766a6db5d63553336f675e6a42d1c2516de8f559fe44f3:3.4.2",
    "operation": "mutation"
  },
  "pathavailabilityquerydocument": {
    "hash": "c21c73251dccb15afb70937a98afa020dccaf8fa8a8003bff0dcf6ea6ffa686f:3.4.2",
    "operation": "query"
  },
  "pathbyidquerydocument": {
    "hash": "ccb39f70e8600870d340d253f4e098cf994b2c3ed01408f3d320731081bea540:3.4.2",
    "operation": "query"
  },
  "pathcontentquerydocument": {
    "hash": "1aa5e1082eb1dec972889039ae78f58c8e91cb11e126f36639eae87900d58475:3.4.2",
    "operation": "query"
  },
  "pathcreatetypecontentmutationdocument": {
    "hash": "34a5a2afc2b5434ed316d2fbdee8229f3ea5550b8562e08a01949ab9757f45ca:3.4.2",
    "operation": "mutation"
  },
  "pathcreatewithclonedcontentmutationdocument": {
    "hash": "195df2260953ab1dd675bc25f8057c523a7cedea32ab8eeed1b1b23a066d163c:3.4.2",
    "operation": "mutation"
  },
  "pathcreatewithexistingcontentmutationdocument": {
    "hash": "16a8ff2460371428e7c11c25d8919e201170c10c5d78f79468b8c736da03e8e2:3.4.2",
    "operation": "mutation"
  },
  "pathdeletemutationdocument": {
    "hash": "d696f912d13b1e2c2f8a3939c2855734fb2a22d4d9e4889e5f15e55484f73a53:3.4.2",
    "operation": "mutation"
  },
  "pathinfoquerydocument": {
    "hash": "4d3eafac6fa8c4495fac06d4e682c1cbd577d26bd96c501e04b6e0e41949c745:3.4.2",
    "operation": "query"
  },
  "paths404querydocument": {
    "hash": "46de23986e34b3d6633c0a003bb62bcf2c6941332bc4097d2c6893dd7b1692b1:3.4.2",
    "operation": "query"
  },
  "pathsetcanonicalmutationdocument": {
    "hash": "3d82b6c19cfcc5d4372b01fe5a76ba0989380c4799c06e50701b3ad31e8a53d4:3.4.2",
    "operation": "mutation"
  },
  "pathsetprotectionmutationdocument": {
    "hash": "c115bbd3e422c0d5d5054f9c130f2736403f6f53c0b619af3e10fbd9c388d663:3.4.2",
    "operation": "mutation"
  },
  "pathslistquerydocument": {
    "hash": "c214e018b8ef23547bae9d7ca79a05247bc6e8de1f07a65e6a32e78b565198ba:3.4.2",
    "operation": "query"
  },
  "pathsmaintenancequerydocument": {
    "hash": "90f6cbe8ff8e006429402d39f60e5eeb20995ef12afa32599500285f454cf129:3.4.2",
    "operation": "query"
  },
  "pathspublishstatequerydocument": {
    "hash": "de6182dd2d368bcc5b9e750de5d7a96ae57dbb69a9bae29a05595a2eef03ca3f:3.4.2",
    "operation": "query"
  },
  "pathupdatemutationdocument": {
    "hash": "ed63c35082cd52db31fe50d3f83fef5e7f56522fe8f86990ce4cd521ba78854f:3.4.2",
    "operation": "mutation"
  },
  "projectassigndomainmutationdocument": {
    "hash": "a5547acd0dcbc48914e59370537dac56b0118f1d8e5e291036dba7af100a1e54:3.4.2",
    "operation": "mutation"
  },
  "projectassignedandsystemusersquerydocument": {
    "hash": "e9ef64241e163fea0115e99b47fd918daf41bc901242d16f7f0a85643f36fd88:3.4.2",
    "operation": "query"
  },
  "projectassignedsystemusersquerydocument": {
    "hash": "20bf829ca6e5214c7dbce93d43a61bf66524a4f87ba0376d7b0a5c5bd3152e29:3.4.2",
    "operation": "query"
  },
  "projectassignedusersquerydocument": {
    "hash": "8c31adc93ad34e4704e880cb5d8d4b4889f458e7c50c073407573bc2413bce83:3.4.2",
    "operation": "query"
  },
  "projectauthlinkdisablemutationdocument": {
    "hash": "c22e3c03d9991ce27728b263740d1553f1fa6f4e6b7b74cfca9b7519f2a40b35:3.4.2",
    "operation": "mutation"
  },
  "projectauthlinkenablemutationdocument": {
    "hash": "6ac6dfb0d5082d56818d0091b0076687a7ad52f7a27e799f85970fe0c89f05d3:3.4.2",
    "operation": "mutation"
  },
  "projectclonemutationdocument": {
    "hash": "35cea75f35a990a723f74e68101658aaf4dc9d07ecf80b3727e223cc92d1d5c0:3.4.2",
    "operation": "mutation"
  },
  "projectcreatemutationdocument": {
    "hash": "cd2bc6ed5619b75d57c579edf7cfcd06502e6d91cc37a4c770fcfb108d5e9905:3.4.2",
    "operation": "mutation"
  },
  "projectdeletemutationdocument": {
    "hash": "b06dd12ed8eb6f1be82310b4496642bfc12d72298ffec9f53e5d44bdecf4916e:3.4.2",
    "operation": "mutation"
  },
  "projectpreferencesquerydocument": {
    "hash": "13eda80e8cd043a12e68586907a0b63ab69b8382d554d82dd0d167294ea5b29f:3.4.2",
    "operation": "query"
  },
  "projectquerydocument": {
    "hash": "7f322bb803b5c521e98aee83e636ebec9a535dd98910f6a88b3336ecd20c9c85:3.4.2",
    "operation": "query"
  },
  "projectswitchmutationdocument": {
    "hash": "7b59885f5bf71612076983fb52502be630a50d91f84fdeb85819c75f9fbb96ed:3.4.2",
    "operation": "mutation"
  },
  "projectupdatenamemutationdocument": {
    "hash": "d6fe541cd0166643e2a13e180a72ffbabacf8fe3da144daf22507a1d63a1e99b:3.4.2",
    "operation": "mutation"
  },
  "projectupdatesettingsmutationdocument": {
    "hash": "10209330a53b3c4aa21af6c05422f3646ac381ef79d1492fd8ec4ddbfee4d560:3.4.2",
    "operation": "mutation"
  },
  "projectuserswitchmutationdocument": {
    "hash": "908149f13c4ceff33794eccc40150c849b6d1b26e4b3679d281a4248a4acf2f8:3.4.2",
    "operation": "mutation"
  },
  "publicpathsquerydocument": {
    "hash": "b1ff6cbb60d4269113d236bfc35d8b2518a62d2208b68e5aa2ecf4c876eb60ac:3.4.2",
    "operation": "query"
  },
  "publishedcontentquerydocument": {
    "hash": "26a680625e4addc44ef2e50032dac77da82d9966c4b7010cc948c73a1ea3da40:3.4.2",
    "operation": "query"
  },
  "redirectcreatemutationdocument": {
    "hash": "984176d245df4f6bbf0567216890935021f57e99c14679b96e04a937d402a870:3.4.2",
    "operation": "mutation"
  },
  "redirectdeletemutationdocument": {
    "hash": "e30c4cc4a3051ea00adb5f5348b25fba540686e1ee2ad05ec0a724f331af886f:3.4.2",
    "operation": "mutation"
  },
  "redirectsquerydocument": {
    "hash": "6824e3f27c42487798caf539989346f66efe57fea80510da0450fb5c36a5ecc6:3.4.2",
    "operation": "query"
  },
  "redirectupdatemutationdocument": {
    "hash": "582307f0c4250dbcca67b35e42215eee8701733ed2e155d2b05f5d2d2d7290c9:3.4.2",
    "operation": "mutation"
  },
  "renamereusablecontentblockdocument": {
    "hash": "7e59b5ed88b428c464c3ee37cfb42bc66ada65092085269abbb92a057777f5a6:3.4.2",
    "operation": "mutation"
  },
  "replacecontentdatawithexistingcontentdocument": {
    "hash": "e0b0756aafa949df91766e95d636a44242b447fe7ac6975496b087d6fb72b786:3.4.2",
    "operation": "mutation"
  },
  "requestcontentblocksbyaidocument": {
    "hash": "bf9a5e7d5b9b0c514f44898a57c177964777b2a7b75c6eceff36cd1df6105664:3.4.2",
    "operation": "mutation"
  },
  "resourcebyslugquerydocument": {
    "hash": "e939c1c2b2de2befc16580cc11d1bb5da8e2ce6998cd8969a7b9098ff08e559b:3.4.2",
    "operation": "query"
  },
  "resourcecreatemutationdocument": {
    "hash": "feeda94490dd76fd5e6da78f87a0202d348aaefcc0ccb09353c781606bf97b6c:3.4.2",
    "operation": "mutation"
  },
  "resourcedeletemutationdocument": {
    "hash": "af6ae75df06aba6661b8435b271de988105978bbef47738e1ddf247ef718e717:3.4.2",
    "operation": "mutation"
  },
  "resourcesquerydocument": {
    "hash": "3204a40f67a41ed3b302edc2fc57ae24f471a4687a6e3080e0ef4df82940ae2d:3.4.2",
    "operation": "query"
  },
  "resourceupdatemutationdocument": {
    "hash": "379ad843d2d1f7c0f835c59efd5322e694d921eac1f5d64952fd272a07bd9c35:3.4.2",
    "operation": "mutation"
  },
  "reusablecontentblockassignmentsdocument": {
    "hash": "ce816d98abc301282d9a9aefc0367fb09a6c6619e775d3b8a9b2f4b9bb7eeb10:3.4.2",
    "operation": "query"
  },
  "reusablecontentblocksquerydocument": {
    "hash": "19ff4d9e58a44cf037dfd0e1863fb438d2044408fd4709fc0a3acec40a2a353a:3.4.2",
    "operation": "query"
  },
  "reusablecontentblocksupdatedsubscriptiondocument": {
    "hash": "e04fc6e118230a00d9eb34d3e496e2b0f09b4095c8d853dd714264ced6bfab84:3.4.2",
    "operation": "subscription"
  },
  "reusablecontentblocksupdatemutationdocument": {
    "hash": "3699e6e04beebfdbc778ab47bb098e6ef30e141c8b3115bc8c34d8f5cbd91b57:3.4.2",
    "operation": "mutation"
  },
  "rootpathquerydocument": {
    "hash": "ade734065291bbfcbbf57d43fa6af2cd6e54087889da5651fd4289b7fe48b94d:3.4.2",
    "operation": "query"
  },
  "routeresolverpathsquerydocument": {
    "hash": "fdfa9474f6c559f4889ef0dbb9099ea014e86317c139ce8abb9ef13de2a596a2:3.4.2",
    "operation": "query"
  },
  "scrapepagemetadocument": {
    "hash": "99e4ad633a82dd8c24dac1bb9b90fd8020c5ed3780747b9f5c3ac5df9c9a9f41:3.4.2",
    "operation": "query"
  },
  "setasnonrenderablecontentblocksmutationdocument": {
    "hash": "526474cac20a1673f2c21a34459d3e4ceacd36b90c77796a6f7c0c6ba8f32f81:3.4.2",
    "operation": "mutation"
  },
  "setasrenderablecontentblocksmutationdocument": {
    "hash": "49796b684e444bc3b44ed9212bdeec760307ada76c0155517c6efd25fdf669cc:3.4.2",
    "operation": "mutation"
  },
  "setasreusablecontentblockdocument": {
    "hash": "cd755620ac2643f611b7a84247a2f0ba8be72e1b471ef6765b9e374bbc4f93ec:3.4.2",
    "operation": "mutation"
  },
  "setinteractiononcontentblockdocument": {
    "hash": "935254a70812e96b0bdce7ce776d920e11c1bd2bfa2ed9d54ab5e514c54ca96d:3.4.2",
    "operation": "mutation"
  },
  "snippetbyidquerydocument": {
    "hash": "945333a100a10e0275c7e0a03c4dad2219449c6d173aaee4c9f9da26d437d6c5:3.4.2",
    "operation": "query"
  },
  "snippetcreatedsubscriptiondocument": {
    "hash": "485a24de24444be08e56dfdb2997c9505eefbaeb4bd72d76e2dbc49830380187:3.4.2",
    "operation": "subscription"
  },
  "snippetdeletedsubscriptiondocument": {
    "hash": "c33872280c7370608d7739c2a47b035dfafc3b2e78a301f26d9fa97f83d5712b:3.4.2",
    "operation": "subscription"
  },
  "snippetdiscardedsubscriptiondocument": {
    "hash": "2852be8218b7249b01b134e3b56df2f4041ec131c4f65e83f354899e637dd38b:3.4.2",
    "operation": "subscription"
  },
  "snippetissuedsubscriptiondocument": {
    "hash": "bf3980b951d2028eea18129df59862835bffc6cc1351ea48ecf54ef8f6af77c1:3.4.2",
    "operation": "subscription"
  },
  "snippetsdataquerydocument": {
    "hash": "0e6dbe4a15c18c37d12a1790db4b8eb504c81e9b4bc04ae3d8012cc74f7a47b7:3.4.2",
    "operation": "query"
  },
  "snippetsupdatedsubscriptiondocument": {
    "hash": "6046d5543545766f72766565c8a10b29ae1bb1628067773c2f2b2dbb3a4f348f:3.4.2",
    "operation": "subscription"
  },
  "suggestprojectinternaldomainsdocument": {
    "hash": "b23d6d6c335dd99d1ceed95f03d23f587b12c2f4a4903cf3da9ff7249da62930:3.4.2",
    "operation": "query"
  },
  "sysgetintercomuserhashdocument": {
    "hash": "096f70f664b6754222264cdf77f3db8b9377fb5b40049e141ca3cad7880c949f:3.4.2",
    "operation": "query"
  },
  "sysgetwebusertokenquerydocument": {
    "hash": "f1f6808d2010121554cbdd7c3a04cfe7255b8d809c48c5223fb391f5170a2df5:3.4.2",
    "operation": "query"
  },
  "templateslistquerydocument": {
    "hash": "276c1bf172e80c12ee388dad1d497bddd50913da4d7d8f4c3e034e398943d78a:3.4.2",
    "operation": "query"
  },
  "templatesquerydocument": {
    "hash": "ce483a16f4821527bc32e974f68cf2466b24c1c40d6fe7d8b406a62efd7a4af7:3.4.2",
    "operation": "query"
  },
  "triggercustomeventmutationdocument": {
    "hash": "c2b3efd5df550e348ad97c058920c2ea513ff3bd47a60445dcd6011ed2ca6842:3.4.2",
    "operation": "mutation"
  },
  "typeformgetformbyidquerydocument": {
    "hash": "bcb9ff088b371e30339f671fcfbf39672085d48fe6f32c03aa11a815ec56942d:3.4.2",
    "operation": "query"
  },
  "typeformgetformsquerydocument": {
    "hash": "d30387e1ea453da3efaef2d7db7026bc56073fce8e3a2afc85ad992befdf0a67:3.4.2",
    "operation": "query"
  },
  "updatecontentdatadocument": {
    "hash": "6adb0aadde62a01cdc9dd6973867073e91da0b1d213363e9540355878ebfad2a:3.4.2",
    "operation": "mutation"
  },
  "updatesnippetmutationdocument": {
    "hash": "3a14e7b6d9f6a16cf091c34a1ee022214c1d1903f780ff961c9cab89739fb72e:3.4.2",
    "operation": "mutation"
  },
  "updatesnippetsmutationdocument": {
    "hash": "96e8832916dab57347ee0de2b6c895e30910ab2e6e15b2d21f1a1d9dc00c2b42:3.4.2",
    "operation": "mutation"
  },
  "updateusermutationdocument": {
    "hash": "5f71b5b987462029a502023478246f2ebdc1a941eea6d8d03d0eed11c9c74deb:3.4.2",
    "operation": "mutation"
  },
  "updateuserwithpermissionsmutationdocument": {
    "hash": "2f9d22c1522e91b8c3dcbcad42769e2338018882b24182edae011be64fc7f6aa:3.4.2",
    "operation": "mutation"
  },
  "uploadmutationdocument": {
    "hash": "78e3bdf5c1d453344637d3cd63732b4aa78a9772646507579ee198fdabd866f0:3.4.2",
    "operation": "mutation"
  },
  "userassignedprojectsdocument": {
    "hash": "b5731eeab8c369c252a7e7b5aa275499cf10ffaad834cc23980d7cff31fe9e4b:3.4.2",
    "operation": "query"
  },
  "userassignedprojectssearchbyiddocument": {
    "hash": "b46d6d6771d9a72789d91740e7248963c343ca6dd550c21f3175b2d0eac9589d:3.4.2",
    "operation": "query"
  },
  "userassignedprojectssearchbynamedocument": {
    "hash": "1bcaafc52f283ff9e11943f0729b13ab663bdd76da9606abc295039eee7038a1:3.4.2",
    "operation": "query"
  },
  "usercreatedatquerydocument": {
    "hash": "7d7729876111662e2c7bdc3b3a52fc53c507315f168b5bd467f3805cdd34e470:3.4.2",
    "operation": "query"
  },
  "usersegmentationquerydocument": {
    "hash": "bf86f743a7000f92704b64ce1d13ca2dcdd4090a35e7d7b6ccc571894a37a76e:3.4.2",
    "operation": "query"
  },
  "userunassignmutationdocument": {
    "hash": "31b943675c1f85a6f0ac8f187e9b2d60c1f63196ab387206b5cccf1d40127bd5:3.4.2",
    "operation": "mutation"
  },
  "versionquerydocument": {
    "hash": "3eaaafd04af01ec036ba95964584a148c2b56d9f7a7f763d8e17bb3b3a165d63:3.4.2",
    "operation": "query"
  },
  "whiteboardcontententitiesdeletemutationdocument": {
    "hash": "91728c9a087c16f6fd279a946eafbb593d6e51d59bdabc1d2d16b9509290bf0c:3.4.2",
    "operation": "mutation"
  },
  "whiteboardcontententitiesmetaupdatemutationdocument": {
    "hash": "c3f1aa72d97ac22f45fda28621ac3587b1993c48490b9f5126832d324b033764:3.4.2",
    "operation": "mutation"
  }
}