import clsx from 'clsx'
import AddBlockIcon from './icons/AddBlockIcon'
import BlockIcon from './icons/BlockIcon'
import CaretCollapse from './icons/CaretCollapse'
import DropdownIndicatorIcon from './icons/DropdownIndicatorIcon'
import GalleryIcon from './icons/GalleryIcon'
import GridIcon from './icons/GridIcon'
import HorizontalGap from './icons/HorizontalGap'
import LetterSpacing from './icons/LetterSpacing'
import LineHeight from './icons/LineHeight'
import MaxHeight from './icons/MaxHeight'
import MaxWidth from './icons/MaxWidth'
import MinHeight from './icons/MinHeight'
import MinWidth from './icons/MinWidth'
import MobileX from './icons/MobileX'
import MoveToolIcon from './icons/MoveToolIcon'
import RadiusBl from './icons/RadiusBl'
import RadiusBr from './icons/RadiusBr'
import RadiusTl from './icons/RadiusTl'
import RadiusTr from './icons/RadiusTr'
import SceneLogo from './icons/SceneLogo'
import SceneLogoInvert from './icons/SceneLogoInvert'
import SceneToolbarLogo from './icons/SceneToolbarLogo'
import ShadowIcon from './icons/ShadowIcon'
import SidesB from './icons/SidesB'
import SidesBl from './icons/SidesBl'
import SidesBlr from './icons/SidesBlr'
import SidesBlrt from './icons/SidesBlrt'
import SidesBlt from './icons/SidesBlt'
import SidesBr from './icons/SidesBr'
import SidesBrt from './icons/SidesBrt'
import SidesBt from './icons/SidesBt'
import SidesL from './icons/SidesL'
import SidesLr from './icons/SidesLr'
import SidesLrt from './icons/SidesLrt'
import SidesLt from './icons/SidesLt'
import SidesNone from './icons/SidesNone'
import SidesR from './icons/SidesR'
import SidesRt from './icons/SidesRt'
import SidesT from './icons/SidesT'
import VerticalGap from './icons/VerticalGap'
import Threads from './icons/Threads'
import X from './icons/X'
import ReusableBlock from './icons/ReusableBlock'
import ReusableBlockDetach from './icons/ReusableBlockDetach'
import ReusableBlockMake from './icons/ReusableBlockMake'
import SceneLogoGray from './icons/SceneLogoGray'
import Collapse from './icons/Collapse'
import Expand from './icons/Expand'
import ChatCircle from './icons/ChatCircle'
import Sticker from './icons/Sticker'
import ImageSquare from './icons/ImageSquare'
import PlusSquare from './icons/PlusSquare'
import SparkleCircle from './icons/SparkleCircle'
import ArrowsMinimize from './icons/ArrowsMinimize'
import ArrowsMaximize from './icons/ArrowsMaximize'
import Webflow from './icons/Webflow'
import Sparkle from './icons/Sparkle'
import Exclamation from './icons/ExclamationMark'

export const SCENE_ICONS = {
  'add-block-icon': AddBlockIcon,
  'block-icon': BlockIcon,
  'caret-collapse': CaretCollapse,
  'exclamation-mark': Exclamation,
  'dropdown-indicator': DropdownIndicatorIcon,
  gallery: GalleryIcon,
  grid: GridIcon,
  'horizontal-gap': HorizontalGap,
  'letter-spacing': LetterSpacing,
  'line-height': LineHeight,
  'max-height': MaxHeight,
  'max-width': MaxWidth,
  'min-height': MinHeight,
  'min-width': MinWidth,
  'mobile-x': MobileX,
  'move-tool': MoveToolIcon,
  'radius-bl': RadiusBl,
  'radius-br': RadiusBr,
  'radius-tl': RadiusTl,
  'radius-tr': RadiusTr,
  'scene-logo': SceneLogo,
  'scene-logo-invert': SceneLogoInvert,
  'scene-toolbar-logo': SceneToolbarLogo,
  'scene-logo-gray': SceneLogoGray,
  'shadow-icon': ShadowIcon,
  'sides-b': SidesB,
  'sides-bl': SidesBl,
  'sides-blr': SidesBlr,
  'sides-blrt': SidesBlrt,
  'sides-blt': SidesBlt,
  'sides-br': SidesBr,
  'sides-brt': SidesBrt,
  'sides-bt': SidesBt,
  'sides-l': SidesL,
  'sides-lr': SidesLr,
  'sides-lrt': SidesLrt,
  'sides-lt': SidesLt,
  'sides-none': SidesNone,
  'sides-r': SidesR,
  'sides-rt': SidesRt,
  'sides-t': SidesT,
  'vertical-gap': VerticalGap,
  threads: Threads,
  x: X,
  'reusable-block': ReusableBlock,
  'reusable-block-detach': ReusableBlockDetach,
  'reusable-block-make': ReusableBlockMake,
  collapse: Collapse,
  expand: Expand,
  'chat-circle': ChatCircle,
  sticker: Sticker,
  'image-square': ImageSquare,
  'plus-square': PlusSquare,
  'sparkle-circle': SparkleCircle,
  'arrows-minimize': ArrowsMinimize,
  'arrows-maximize': ArrowsMaximize,
  webflow: Webflow,
  sparkle: Sparkle,
}

type SceneIconProps = {
  className?: string
  style?: React.CSSProperties
  icon: keyof typeof SCENE_ICONS
}

export function SceneIcon({ className, style, icon }: SceneIconProps) {
  if (!Object.keys(SCENE_ICONS).includes(icon)) {
    console.error('[Icons] - icon not found')
    return null
  }
  const IconComponent = SCENE_ICONS[icon]

  return (
    <IconComponent
      className={clsx('svg-inline--sc', className)}
      style={style}
    />
  )
}
