import clsx from 'clsx'
import { unflatten } from 'flat'
import { search } from 'jmespath'
import { useState } from 'react'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { SaveButton } from '../../atoms/SaveButton/SaveButton'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { SnippetHeader } from '../../atoms/SnippetHeader/SnippetHeader'
import { RHFStateTabs } from '../../atoms/StateTabs/StateTabs'
import {
  FormContextProvider,
  useFormContext,
} from '../../form/context/FormContext'
import { RHFBorderSettingsPropType } from './BorderSettings'
import { StateBorderFields } from './components/StateBorderFields'

export const DefaultBorderSettings = ({
  basePath,
  className,
  stateTabs: {
    inheritedState,
    allowHover = false,
    allowActive = false,
    activeStateLabel = 'Active',
  } = {},
}: RHFBorderSettingsPropType) => {
  const valuesPath = `${basePath ? basePath + '.' : ''}data`
  const {
    values,
    unregister,
    register,
    registerValues,
    onCreateSnippet,
    onDetachSnippet,
    onAssignSnippet,
    onIssueSnippet,
    onDeleteSnippet,
    onDiscardSnippet,
    allowSaveSnippet,
    parentSnippet,
    meta,
    disabledSnippetActions,
  } = useFormContext({
    snippetsType: 'STROKE',
    metaPath: valuesPath,
    name: basePath,
  })
  const [editSnippetMode, setEditSnippetMode] = useState(false)
  const isSnippet = Object.keys(meta).length > 0
  const hasSnippetUnpublishedChanges = meta.hasUnpublishedChanges
  const snippetId = meta.id

  const filteredBorderValues = Object.entries(values).reduce(
    (acc, [key, val]) => {
      if (!key.includes('radius') && !key.includes('meta')) {
        return {
          ...acc,
          [key]: val,
        }
      }

      return acc
    },
    {},
  )

  const hasExistingValues = Object.keys(filteredBorderValues).some((val) =>
    val.startsWith(`${valuesPath}`),
  )

  const showSettings =
    (hasExistingValues && !isSnippet) ||
    (isSnippet && hasSnippetUnpublishedChanges) ||
    (isSnippet && editSnippetMode)

  const onAssignSnippetCallback = (
    id: string,
    options?: { shouldReplace?: boolean },
  ) => {
    onAssignSnippet({
      id,
      fieldName: valuesPath,
      parentSnippetId:
        (options?.shouldReplace ? undefined : snippetId) || parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  const onDetachSnippetCallback = () => {
    onDetachSnippet({
      id: snippetId,
      fieldName: valuesPath,
      parentSnippetId: parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  return (
    <FormContextProvider
      parentSnippet={{
        id: snippetId,
        path: meta.snippetPath,
        hasUnpublishedChanges: hasSnippetUnpublishedChanges,
      }}
    >
      <SegmentWrapper className={clsx('sc-rhf-border-settings', className)}>
        {isSnippet ? (
          <SnippetHeader
            snippetType="STROKE"
            snippetName={meta.name}
            snippetId={snippetId}
            editSnippetMode={editSnippetMode}
            snippetHasOverrides={hasSnippetUnpublishedChanges}
            onAssignSnippet={onAssignSnippetCallback}
            detachButton={{
              show: !disabledSnippetActions?.includes('detach'),
              onClick: onDetachSnippetCallback,
            }}
            editButton={{
              show: !hasSnippetUnpublishedChanges,
              onClick: () => setEditSnippetMode(!editSnippetMode),
            }}
            removeButton={{
              show: true,
              onClick: () => unregister([`${valuesPath}`]),
            }}
            resetButton={{
              show: hasSnippetUnpublishedChanges,
              onClick: () => {
                setEditSnippetMode(false)
                onDiscardSnippet({ id: snippetId })
              },
            }}
          />
        ) : (
          <HeaderSegment
            heading="Stroke"
            addButton={{
              show: !hasExistingValues,
              onClick: () => register(registerValues),
            }}
            removeButton={{
              show: hasExistingValues,
              onClick: () => unregister(Object.keys(filteredBorderValues)),
            }}
            snippetsDropdown={{
              show: true,
              showSaveButton: hasExistingValues,
              snippetsType: 'STROKE',
              onCreateSnippet: (snippetName) => {
                onCreateSnippet({
                  name: snippetName,
                  data: {
                    value: search(unflatten(filteredBorderValues), valuesPath),
                  },
                  meta: {
                    shouldMergeData: true,
                  },
                  type: 'STROKE',
                  fieldName: valuesPath,
                  parentSnippetId: snippetId || parentSnippet?.id,
                  parentSnippetPath: parentSnippet?.path,
                })
              },
              onDeleteSnippet,
              onAssignSnippet: onAssignSnippetCallback,
            }}
          />
        )}
        {showSettings && (
          <div className="tw-flex tw-flex-col tw-gap-2">
            {!allowHover && !allowActive && !inheritedState && (
              <StateBorderFields state={'default'} valuesPath={valuesPath} />
            )}
            {inheritedState && (
              <StateBorderFields
                state={inheritedState}
                valuesPath={valuesPath}
              />
            )}
            {!inheritedState && (allowHover || allowActive) && (
              <RHFStateTabs
                disabledTabs={[
                  ...(!allowHover ? ['hover'] : []),
                  ...(!allowActive ? ['active'] : []),
                ]}
                activeStateLabel={activeStateLabel}
                defaultStateTabContent={
                  <StateBorderFields
                    state={'default'}
                    valuesPath={valuesPath}
                  />
                }
                hoverStateTabContent={
                  <StateBorderFields state={'hover'} valuesPath={valuesPath} />
                }
                activeStateTabContent={
                  <StateBorderFields state={'active'} valuesPath={valuesPath} />
                }
              />
            )}
            {hasSnippetUnpublishedChanges && allowSaveSnippet && (
              <SaveButton
                onClick={() => {
                  onIssueSnippet({ id: snippetId, name: meta.name })
                  setEditSnippetMode(false)
                }}
              />
            )}
          </div>
        )}
      </SegmentWrapper>
    </FormContextProvider>
  )
}
