import { EditorState, ParagraphNode } from 'lexical'
import { useContext, useRef } from 'react'
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { WysiwygContext } from './WysiwygProvider'
import { OnEditorStateChangePlugin } from './plugins/onEditorStateChange/OnEditorStateChangePlugin'
import { OnChangePlugin } from './plugins/onChange/OnChangePlugin'
import wysiwygConfig from '@sceneio/config-wysiwyg'
import type { CommonEditorType } from './types'
import FloatingItemsToolbarPlugin from './plugins/floatingItemsToolbar/FloatingItemsToolbarPlugin'
import { debounce } from '@sceneio/tools/lib/debounce'
import { ArrayItemNode } from '@sceneio/wysiwyg-nodes/lib/ArrayItemNode'
import { LineBreakOnEnterPlugin } from './plugins/lineBreakOnEnterPlugin/LineBreakOnEnterPlugin'
import { FixPlaceholderPlugin } from './plugins/fixPlaceholderPlugin/FixPlaceholderPlugin'

export type WysiwygItemsEditor = CommonEditorType & {
  itemAdd?: () => { id: string; value: any }
  itemRemove?: () => { id: string; value: any }
  disableItemAdd?: boolean
  disableItemRemove?: boolean
}

export function WysiwygItemsEditor({
  componentId,
  serialisedEditorState,
  itemAdd,
  itemRemove,
  disableItemAdd = false,
  disableItemRemove = false,
}: WysiwygItemsEditor) {
  const containerRef = useRef<HTMLDivElement>(null)
  const { onSubmit, anchorRef, resolvePathFromId } = useContext(WysiwygContext)
  const hasBeenDeleted = useRef(false)

  const initialConfig: InitialConfigType = {
    ...wysiwygConfig,
    namespace: 'ItemsEditor',
    editorState: serialisedEditorState,
    nodes: [
      ...wysiwygConfig.nodes,
      {
        replace: ParagraphNode,
        with: (node) => {
          return new ArrayItemNode()
        },
      },
    ],
    onError: (error: Error) => {
      console.log(error)
    },
  }

  function handleChange(editorState: EditorState) {
    const editorStateJSON = editorState.toJSON()
    const serializedEditorState = JSON.stringify(editorStateJSON)
    if (resolvePathFromId && !hasBeenDeleted.current) {
      onSubmit({
        name: resolvePathFromId(componentId),
        data: serializedEditorState,
        operation: 'UPDATE',
      })
    }
  }

  function onAdd() {
    if (itemAdd) {
      const { id, value } = itemAdd()
      if (resolvePathFromId) {
        onSubmit({
          name: resolvePathFromId(id),
          data: value,
          isItemArray: true,
          operation: 'ADD',
        })
      }
    }
  }

  function onRemove() {
    if (itemRemove) {
      const { id, value } = itemRemove()
      if (resolvePathFromId) {
        hasBeenDeleted.current = true
        onSubmit({
          name: resolvePathFromId(id),
          data: value,
          isItemArray: true,
          operation: 'DELETE',
        })
      }
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div
        ref={containerRef}
        className="wysiwyg-container notranslate"
        data-no-drag
      >
        <FixPlaceholderPlugin>
          <RichTextPlugin
            contentEditable={
              <div className="wysiwyg">
                <ContentEditable className="wysiwyg-input" />
              </div>
            }
            placeholder={
              <div className="wysiwyg-placeholder">Start Typing...</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </FixPlaceholderPlugin>
        <HistoryPlugin />
        <OnChangePlugin onChange={debounce(handleChange, 500)} />
        <OnEditorStateChangePlugin
          serialisedEditorState={serialisedEditorState || ''}
        />
        <LineBreakOnEnterPlugin />
        {anchorRef.current && (
          <FloatingItemsToolbarPlugin
            containerRef={containerRef}
            onAdd={onAdd}
            onRemove={onRemove}
            disableAdd={disableItemAdd}
            disableRemove={disableItemRemove}
            anchorElem={anchorRef.current}
          />
        )}
      </div>
    </LexicalComposer>
  )
}
