import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import clsx from 'clsx'
import { Button, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  DataTableColInternalItemWithCallback,
  DataTableCols,
} from '../dataTableTypes'
import { Icon } from '@sceneio/ui-icons'
import useOnclickOutside from 'react-cool-onclickoutside'

type Props<DataItem> = {
  managedCols: DataTableColInternalItemWithCallback[]
  givenCols: DataTableCols<DataItem>
  isSomeColStatusChanged: boolean
}

export function DataTableColsManager<DataItem>({
  managedCols,
  givenCols,
  isSomeColStatusChanged,
}: Props<DataItem>) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const buttonClasses = clsx(
    'data-table__cols-manager-btn d-flex align-items-center',
    {
      'text-primary': isSomeColStatusChanged,
    },
  )

  const ref = useOnclickOutside(
    () => {
      setIsModalOpen(false)
    },
    {
      ignoreClass: 'data-table__cols-manager-btn',
    },
  )
  return (
    <div className="position-relative d-flex align-items-stretch">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="table-columns-manager-cogs-btn">
            Table columns manager
          </Tooltip>
        }
      >
        <Button
          className={buttonClasses}
          variant="transparent"
          size="sm"
          onClick={() => setIsModalOpen(!isModalOpen)}
        >
          <Icon provider="phosphor" icon="SquareSplitHorizontal" size="20px" />
        </Button>
      </OverlayTrigger>
      <div
        ref={ref}
        className={clsx('data-table__cols-manager-menu', {
          'd-none': !isModalOpen,
        })}
      >
        <ul className="list-group my-3">
          {managedCols.map((item) => (
            <li key={item.key} className="list-group-item border-0 py-1">
              <InputGroup className="justify-content-between align-items-center">
                <Form.Label className="mb-0">
                  {givenCols[item.key]?.label}
                </Form.Label>
                <Form.Check
                  type="switch"
                  id={item.key}
                  onChange={item.onChange}
                  checked={item.status}
                />
              </InputGroup>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
