import { useEffect, useState, useCallback } from 'react'
import { calculatePaginator } from '../dataTableUtils'
import type {
  DataTableDataItem,
  DataTablePagination,
  DataTableSortingType,
  HookUseDataTableProps,
} from '../dataTableTypes'
import { stringify } from '@sceneio/tools/lib/stringify'
import { usePersistentState } from '@sceneio/hooks/lib/usePersistentState'

function sortAndPaginateData<D extends DataTableDataItem>(
  data: D[],
  paginator: DataTablePagination,
  sorting: DataTableSortingType,
) {
  let validData = [...data] // Creates a shallow copy of data
  const { col: sortingCol, direction: sortingDirection } = sorting

  if (sortingCol && sortingDirection) {
    validData = validData.sort((a, b) => {
      const valA = a[sortingCol]
      const valB = b[sortingCol]
      if (sortingDirection.toLowerCase() === 'asc') {
        return valA > valB ? 1 : valA < valB ? -1 : 0
      } else {
        return valA < valB ? 1 : valA > valB ? -1 : 0
      }
    })
  }

  // Ensures the slice indices are within the valid range
  const startIndex = Math.max(0, paginator.from - 1)
  const endIndex = Math.min(validData.length, paginator.to)
  return validData.slice(startIndex, endIndex)
}

export function useDataTable<DataItem extends DataTableDataItem>({
  tableName,
  data = [],
  initialPage = 1,
  itemsPerPage = Infinity,
  initialSorting = {},
}: HookUseDataTableProps<DataItem>) {
  const [sorting, setSorting] = usePersistentState(
    `dataTable:${tableName}:sorting`,
    initialSorting,
  )

  const [paginator, setPaginator] = useState(
    calculatePaginator(data.length, initialPage, itemsPerPage),
  )

  const [tableData, setTableData] = useState<DataItem[]>(() =>
    // sortAndPaginateData(data, paginator, sorting),
    sortAndPaginateData(data, paginator, sorting),
  )

  const onPageChange = useCallback(
    (nextPageNumber: number) => {
      setPaginator(
        calculatePaginator(data.length, nextPageNumber, itemsPerPage),
      )
    },
    [data.length],
  )

  const onSortChange = useCallback(
    (colName: string) => {
      setSorting({
        col: colName,
        direction:
          sorting.col && sorting.col === colName && sorting.direction === 'asc'
            ? 'desc'
            : 'asc',
      })
    },
    [stringify(sorting)],
  )

  useEffect(() => {
    setPaginator(calculatePaginator(data.length, initialPage, itemsPerPage))
  }, [data.length])

  useEffect(() => {
    setTableData(sortAndPaginateData(data, paginator, sorting))
  }, [tableName, stringify(paginator), stringify(sorting), stringify(data)])

  return {
    tableData,
    paginator,
    onPageChange,
    onSortChange,
    sorting,
  }
}
