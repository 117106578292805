import { resolveColAttributeValue } from '../dataTableUtils'
import {
  DataTableCasts,
  DataTableBodyProps,
  DataTableDataItem,
} from '../dataTableTypes'

const casts: DataTableCasts = {
  boolean: [(value) => String(value)],
  number: [(value) => String(value)],
}

function applyCast<Type>(value: Type) {
  const type = typeof value
  if (!casts[type]) return value
  return casts[type].reduce((val, fn) => fn(val), value)
}

export function DataTableBody<DataItem extends DataTableDataItem>({
  data = [],
  cols = {},
  idKey = '',
  row: rowFn,
}: DataTableBodyProps<DataItem>) {
  const shouldParseRowCallback = typeof rowFn === 'function'
  const dataLength = data.length

  const rows = data.map((item, rowsIndex, inputArr) => {
    const rowClasses = 'table-row-hover'

    let rowClassName = rowClasses
    let rowDataAttributes = {}

    if (shouldParseRowCallback) {
      // console.log(inputArr[rowsIndex])
      const { className, RowComponent, dataAttributes } = rowFn({
        item,
        prevItem: inputArr[rowsIndex - 1] ?? null,
        nextItem: inputArr[rowsIndex + 1] ?? null,
        iterator: {
          index: rowsIndex,
          iteration: rowsIndex + 1,
          isFirst: rowsIndex === 0,
          isLast: rowsIndex + 1 === dataLength,
        },
      })

      if (RowComponent) return RowComponent
      // TODO - @saska - rowClasses implementation in row definition (not here)
      if (className) rowClassName += ` ${className}`

      if (dataAttributes) rowDataAttributes = dataAttributes
    }

    return (
      <tr
        key={`tr-${item[idKey]}`}
        className={rowClassName}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rowDataAttributes}
      >
        {Object.keys(cols).map((colName) => {
          const primaryRendered =
            'render' in cols[colName]
              ? cols[colName].render!({
                  value: item[colName],
                  item,
                })
              : applyCast(item[colName])

          const finalRendered =
            'afterRender' in cols[colName]
              ? cols[colName].afterRender!({
                  item,
                  renderedComponent: primaryRendered,
                })
              : primaryRendered

          return (
            <td
              key={`td-${colName}`}
              className={resolveColAttributeValue<DataItem, string>(
                'colClassName',
                '',
                cols[colName],
                item,
              )}
              style={resolveColAttributeValue<DataItem, {}>(
                'colStyle',
                {},
                cols[colName],
                item,
              )}
            >
              {finalRendered}
            </td>
          )
        })}
      </tr>
    )
  })
  //   [stringify(data), stringify(cols), idKey, stringify(collapsible)],
  // )

  return <>{rows}</>
}
