import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type {
  VideoHeroBlock,
  ButtonComponent,
  MediaComponent,
} from '@sceneio/schemas/lib/types'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { mergeDeepRight } from '@sceneio/tools'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { randomString } from '@sceneio/tools'
import {
  DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
  DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
} from '../../../components/Button/constants/globalButtonCssVariables'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../SplitHero/form/additionalFormFieldRenderers'

const MEDIA_DEFAULTS = mediaDefaults.config

export const buttonSampleFactory = (data = {}): ButtonComponent => ({
  id: randomString(8),
  meta: {
    dataType: 'button-component',
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const videoHeroComponentMeta: BlockComponentMeta<VideoHeroBlock> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      meta: {
        dataType: 'block-component',
      },
      minHeight: {
        data: {
          '--min-height-default': 'auto',
        },
      },
      background: {
        fit: 'cover',
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      meta: {
        dataType: 'block-container-component',
      },
      margin: {
        data: {
          '--margin-default-top': 0,
          '--margin-default-left': 0,
          '--margin-default-right': 0,
          '--margin-default-bottom': 0,
        },
      },
      padding: {
        data: {
          '--padding-xs-top': '3rem',
          '--padding-xs-left': '3rem',
          '--padding-xs-right': '3rem',
          '--padding-xs-bottom': '3rem',
          '--padding-default-top': '10rem',
          '--padding-default-left': '8rem',
          '--padding-default-right': '8rem',
          '--padding-default-bottom': '10rem',
        },
      },
      responsiveConfig: {
        xs: {
          maxWidth: 'screen',
        },
        default: {
          rowGap: '1.5rem',
          maxWidth: 'screen',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
    type: 'blockContainer',
  },
  heading: {
    path: 'components.heading',
    name: 'Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.heading',
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
      children: textToLexical('Medium length hero headline goes here', 'h1'),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      color: {
        data: {
          default: {
            '--color-default-default': 'rgb(255, 255, 255)',
          },
        },
      },
      padding: {
        data: {
          '--padding-md-left': '0rem',
          '--padding-xs-left': '0rem',
          '--padding-md-right': '0rem',
          '--padding-xs-right': '0rem',
          '--padding-default-right': '15%',
          '--padding-default-left': '15%',
        },
      },
      as: 'h1',
      show: true,
    },
    type: 'text',
  },
  subheading: {
    path: 'components.subheading',
    name: 'Subheading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.subheading',
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      font: {
        meta: {
          dataType: 'font-style',
        },
      },
      children: textToLexical('Subheading for a block'),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      color: {
        data: {
          default: {
            '--color-default-default': 'rgb(255, 255, 255)',
          },
        },
      },
      as: 'p',
      show: false,
    },
    type: 'text',
  },
  text: {
    path: 'components.text',
    name: 'Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.text',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      font: {
        meta: {
          dataType: 'font-style',
        },
      },
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
      ),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      padding: {
        data: {
          '--padding-md-left': '0rem',
          '--padding-xs-left': '0rem',
          '--padding-md-right': '0rem',
          '--padding-xs-right': '0rem',
          '--padding-default-top': 0,
          '--padding-default-right': '30%',
          '--padding-default-left': '30%',
          '--padding-default-bottom': 0,
        },
      },
      color: {
        data: {
          default: {
            '--color-default-default': 'rgb(255, 255, 255)',
          },
        },
      },
      as: 'p',
      show: true,
    },
    type: 'text',
  },
  author: {
    path: 'components.author',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.author',
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      font: {
        meta: {
          dataType: 'font-style',
        },
      },
      children: textToLexical('Author Name'),
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      color: {
        data: {
          default: {
            '--color-default-default': 'rgb(255, 255, 255)',
          },
        },
      },
      as: 'p',
      show: false,
    },
    name: 'Author',
    type: 'text',
  },
  logo: {
    path: 'components.logo',
    name: 'Logo',
    defaultConfig: {
      meta: {
        dataType: 'image-component',
      },
      responsiveConfig: {
        default: {
          src: 'https://assets.scene.io/global/placeholders/logo-ipsum-vertical.svg',
          width: 170,
        },
      },
      show: false,
    },
    configEditor: {
      formProps: {
        excludedSettings: ['height'],
      },
    },
    type: 'image',
  },
  background: {
    path: 'components.background',
    name: 'Media layer',
    components: {
      credits: {
        path: 'components.credits',
        inlineEditors: [
          {
            editorType: 'inlineText',
            editorPath: 'components.background.components.credits',
          },
        ],
        disableEditing: true,
        name: 'Credits',
        type: 'text',
      },
    },
    defaultConfig: {
      ...(mergeDeepRight(MEDIA_DEFAULTS, {
        components: {
          ...MEDIA_DEFAULTS.components,
          video: {
            show: true,
            duration: {
              font: {
                meta: {
                  dataType: 'font-style',
                },
                data: {
                  default: {
                    '--font-default-family-default': 'arial, sans-serif',
                  },
                },
              },
              show: true,
              color: {
                data: {
                  default: {
                    '--color-default-default': '#fff',
                  },
                },
              },
              margin: {
                data: {
                  '--margin-default-top': '80px',
                },
              },
              textShadow: {
                meta: {
                  dataType: 'text-shadow-style',
                },
                data: {
                  responsiveConfig: {
                    default: {
                      default: [
                        {
                          id: randomString(8),
                          x: '1px',
                          y: '1px',
                          blur: '3px',
                          color: 'rgba(0,0,0,0.3)',
                        },
                      ],
                    },
                  },
                },
              },
            },
            language: 'en',
            className: '',
            showPoster: false,
            showControls: true,
            showPlayButton: true,
            responsiveConfig: {
              default: {
                src: 'https://cdn.scene.io/static/assets/videos/placeholders/video-sample.mp4',
                posterType: 'image',
                posterImageSrc:
                  'https://assets.scene.io/global/placeholders/light_1400x1080.png',
                posterVideoSrc:
                  'https://cdn.scene.io/static/assets/videos/placeholders/video-sample.mp4',
              },
            },
            isBackgroundVideo: true,
          },
        },
        animation: '',
        format: 'video',
        overlay: {
          responsiveConfig: {
            default: 'rgba(0, 0, 0, 0.4)',
          },
        },
        show: true,
      }) as MediaComponent),
    },
    type: 'media',
  },
  buttons: {
    path: 'components.buttons',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Button',
        type: 'button',
        disableToggleShow: true,
        sampleFactory: buttonSampleFactory,
      },
    },
    inlineEditors: [
      {
        editorType: 'buttonEditor',
        editorPath: 'components.buttons.items',
        editorOptions: {
          isItemArray: true,
          sampleFactory: buttonSampleFactory,
        },
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'container-component',
      },
      items: [
        buttonSampleFactory({
          children: textToLexical('CTA Button', 'array-item'),
          ...DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
        }),
        buttonSampleFactory(DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES),
      ] as ButtonComponent[],
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '0.75rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'flex-start',
        },
      },
      padding: {
        data: {
          '--padding-default-top': '1rem',
        },
      },
      show: true,
    },
    name: 'Buttons',
    type: 'container',
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
}

export const videoHeroBlockMeta: BlockMeta<VideoHeroBlock> = {
  defaultConfig: {},
  configEditor: {},
}

export const videoHeroDefaultConfig = blockDefaultConfigFactory<VideoHeroBlock>(
  {
    componentMeta: videoHeroComponentMeta,
    blockMeta: videoHeroBlockMeta,
    version: 1,
  },
)
