import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import Button from '../Button/Button'
import { Container } from '../Container/Container'
import Media from '../Media/Media'
import Text from '../Text/Text'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { CardComponent } from '@sceneio/schemas/lib/types'
import { editorItemsControlsFactory } from '../../tools/editor/editor'
import { buttonSampleFactory } from './cardMeta'
import { getBackgroundCssVariables } from '../../tools/cssVariables/getBackgroundCssVariables'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'
import { resolveBreakpointImageClasses } from '../Image/helpers/resolveBreakpointImageClasses'
import { BreakpointEnum } from '../Image/constants/breakpoints'
import { resolveDefaultImageClasses } from '../Image/helpers/resolveDefaultImageClasses'

function Card({
  direction = 'vertical',
  swapOrder = false,
  gap,
  horizontalAlignment,
  verticalAlignment,
  padding,
  margin,
  boxShadow,
  border,
  background,
  components: {
    media,
    contentContainer,
    tagline,
    heading,
    description,
    buttons,
  },
  cid,
  style,
  editor,
  className,
  preview,
}: CardComponent) {
  const cardClasses = clsx(
    'sc-card',
    {
      'sc-card--vertical': direction === 'vertical',
      'sc-card--horizontal': direction === 'horizontal',
      'sc-card--swap-order': swapOrder,
    },
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    className,
  )

  const {
    fit,
    parallax,
    responsiveConfig: bgResponsiveConfig,
  } = background || {}

  return (
    <div
      data-component-id={editor?.id}
      className={cardClasses}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            horizontalAlignment,
            verticalAlignment,
            gap,
            padding,
            margin,
            border,
          },
          prefix: 'card',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'card',
        }),
        ...getBackgroundCssVariables({
          background,
          prefix: 'card',
        }),
      }}
    >
      <div className="sc-card__media-col">
        <Media
          {...media}
          components={{
            ...media.components,
            video: {
              ...('video' in media.components && {
                ...media.components.video,
                usePlayer: true,
                classes: 'sc-card__media-video',
              }),
            },
            image: {
              ...('image' in media.components && {
                ...media.components.image,
                classes: 'sc-card__media-image',
                lazyLoadEnable: true,
                isAnimated: true,
              }),
            },
          }}
          editor={{ ...editor, id: `${editor?.id}.media` }}
          cover={direction === 'horizontal'}
          cid={resolveComponentId(cid, 'media')}
          preview={preview}
        />
      </div>
      <div className="sc-card__content-col">
        <Container
          {...contentContainer}
          name="group"
          editor={{
            ...editor,
            id: `${editor?.id}.contentContainer`,
          }}
          flow={{ data: { '--flow-default': 'column' } }}
        >
          <Text
            {...tagline}
            editor={{
              ...editor,
              id: `${editor?.id}.tagline`,
            }}
            cid={resolveComponentId(cid, 'tagline')}
          />
          <Text
            {...heading}
            editor={{
              ...editor,
              id: `${editor?.id}.heading`,
            }}
            cid={resolveComponentId(cid, 'heading')}
          />
          <Text
            {...description}
            editor={{
              ...editor,
              id: `${editor?.id}.description`,
            }}
            cid={resolveComponentId(cid, 'description')}
          />
          <Container
            className="bui-flex-wrap bui-w-100"
            editor={{
              ...editor,
              id: `${editor?.id}.buttons`,
            }}
            {...buttons}
            name="componentArray"
          >
            {buttons?.items?.map(
              (
                {
                  id: buttonId,
                  childrenWrapProps = {},
                  className: buttonClassname,
                  ...restButtonProps
                },
                index,
              ) => (
                <Button
                  editor={{
                    ...editor,
                    id: `${editor?.id}.buttons.items[${index}]`,
                    wysiwyg: {
                      type: 'ITEMS',
                      ...editorItemsControlsFactory({
                        itemsArr: buttons.items || [],
                        itemsId: `${editor?.id}.buttons.items`,
                        itemFactory: buttonSampleFactory,
                        index: index,
                      }),
                    },
                  }}
                  className={clsx('sc-card__button', buttonClassname)}
                  childrenWrapProps={{
                    ...childrenWrapProps,
                  }}
                  key={buttonId}
                  cid={resolveComponentId(cid, `button-${index}`)}
                  {...restButtonProps}
                />
              ),
            )}
          </Container>
        </Container>
      </div>

      {bgResponsiveConfig && Object.keys(bgResponsiveConfig).length && (
        <>
          <div className="sc-card__bg-overlay" />
          {Object.entries(bgResponsiveConfig).map(([bp, config]) => {
            const { image } = config || {}
            return (
              <React.Fragment key={bp}>
                {image?.src && (
                  <div
                    style={{
                      backgroundImage: `url(${image.src}?twic=v1/output=preview)`,
                    }}
                    data-twic-background={`url(${twicPicsSourceUrlResolver(
                      image.src,
                    )}${editor ? `?cover=${fit === 'cover'}` : ''})`}
                    className={clsx('sc-card__bg-image', {
                      'sc-card__bg-image--parallax': parallax,
                      'sc-card__bg-image--cover': fit === 'cover',
                      [resolveBreakpointImageClasses({
                        breakpoint: bp as BreakpointEnum,
                        elementClassName: 'sc-card__bg-image',
                      })]: bp !== 'default',
                      [resolveDefaultImageClasses({
                        breakpointsArr: (
                          Object.keys(bgResponsiveConfig) as BreakpointEnum[]
                        ).filter((bp) => bgResponsiveConfig[bp]),
                        elementClassName: 'sc-card__bg-image',
                      })]: bp === 'default',
                    })}
                  />
                )}
              </React.Fragment>
            )
          })}
        </>
      )}
    </div>
  )
}

export const plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default Card
