import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { createGraphqlAsyncThunkByDocument } from '../../helpers/createGraphqlAsyncThunk'
import type { AppDispatch, RootState } from './../../store'
import {
  ContentModuleType,
  ContentModulesCreateQueryVarsType,
  ContentModulesSliceType,
} from './contentModulesSliceTypes'
import {
  deleteWhiteboardContentEntities,
  updateWhiteboardContentEntitiesMeta,
} from '../whiteboard/whiteboardSlice'
import { WhiteboardContentEntitiesMetaUpdateResType } from '../whiteboard/whiteboardSliceTypes'
import { asyncThunkFetcher } from '../../helpers/asyncThunkFetcher'
export * from './contentModulesSliceMemoizedSelectorHooks'
export * from './contentModulesSliceSelectors'

// ---------------
// Initial State
// ---------------
export const initialState: ContentModulesSliceType = {
  entities: [],
  status: 'idle',
  error: null,
} as ContentModulesSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Thunks
// ---------------

export const fetchContentModules = createGraphqlAsyncThunkByDocument<
  'ContentModulesQueryDocument',
  'contentModules'
>()('contentModules/fetch', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'ContentModulesQueryDocument',
    selector: 'contentModules',
    variables: queryVariables,
    thunkAPI,
  })
})

export const createContentModules = createGraphqlAsyncThunkByDocument<
  'ContentModulesCreateMutationDocument',
  'createContentModules',
  ContentModulesCreateQueryVarsType
>()('contentModules/create', async ({ queryVariables }, thunkAPI) => {
  const contentId = thunkAPI.getState().content.entity?.id

  return await asyncThunkFetcher({
    query: 'ContentModulesCreateMutationDocument',
    selector: 'createContentModules',
    variables: {
      ...queryVariables,
      contentId: contentId!,
    },
    thunkAPI,
  })
})

export const deleteContentModules = createGraphqlAsyncThunkByDocument<
  'ContentModulesDeleteMutationDocument',
  'deleteContentModules'
>()('contentModules/delete', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'ContentModulesDeleteMutationDocument',
    selector: 'deleteContentModules',
    variables: { ...queryVariables },
    thunkAPI,
  })
})

export const updateContentModules = createGraphqlAsyncThunkByDocument<
  'ContentModulesUpdateMutationDocument',
  'updateContentModules'
>()('contentModules/update', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'ContentModulesUpdateMutationDocument',
    selector: 'updateContentModules',
    variables: { ...queryVariables },
    thunkAPI,
  })
})

// ---------------
// Reducer
// ---------------

export const contentModulesSlice = createSlice({
  name: 'contentModules',
  initialState,
  reducers: {
    wsUpdateContentModules: (
      state,
      action: PayloadAction<ContentModuleType[]>,
    ) => {
      if (!state.entities) {
        return
      }

      const updatedContentModules = action.payload

      updatedContentModules?.forEach((contentModuleToUpdate) => {
        const existingContentModuleIndex = state.entities.findIndex(
          (contentModule) => contentModule.id === contentModuleToUpdate.id,
        )

        if (existingContentModuleIndex > -1) {
          const contentModule = state.entities[existingContentModuleIndex]
          // keep base64 image for user who initiated the creation
          // to prevent flickering between base64 and image load
          if (contentModule?.type === 'IMAGE') {
            const isEmptySrc =
              state.entities[existingContentModuleIndex].data.src === ''

            if (isEmptySrc) {
              state.entities[existingContentModuleIndex] = {
                ...contentModule,
                ...contentModuleToUpdate,
              }
            } else {
              state.entities[existingContentModuleIndex] = {
                ...contentModule,
                ...contentModuleToUpdate,
                data: contentModule.data,
              }
            }
          } else {
            state.entities[existingContentModuleIndex] = {
              ...contentModule,
              ...contentModuleToUpdate,
            }
          }
        }
      })
    },
    wsCreateContentModules: (
      state,
      action: PayloadAction<ContentModuleType[]>,
    ) => {
      if (!state.entities) {
        return
      }

      const newContentModules = action.payload

      newContentModules.forEach((contentModule) => {
        const existingContentModuleIndex = state.entities.findIndex(
          (existingContentModule) =>
            existingContentModule.cid === contentModule.cid,
        )

        if (existingContentModuleIndex > -1) {
          state.entities[existingContentModuleIndex] = contentModule
        } else {
          state.entities = [...state.entities, contentModule]
        }
      })
    },
    wsDeleteContentModules: (
      state,
      action: PayloadAction<{ id: string }[]>,
    ) => {
      if (!state.entities) {
        return
      }
      const deletedContentModules = action.payload
      const deletedContentModulesIds = deletedContentModules.map(({ id }) => id)
      state.entities = state.entities.filter(
        ({ id }) => !deletedContentModulesIds?.includes(id),
      )
    },
    wsUpdateWhiteboardContentContentModulesEntity: (
      state,
      action: PayloadAction<WhiteboardContentEntitiesMetaUpdateResType>,
    ) => {
      if (!state.entities) {
        return
      }

      const updatedWhiteboardContentEntities = action.payload
      const updatedWhiteboardContentContentModuleEntities =
        updatedWhiteboardContentEntities.filter(
          ({ entity }) => entity === 'CONTENTMODULE',
        )

      updatedWhiteboardContentContentModuleEntities.forEach((contentModule) => {
        const existingContentModuleIndex = state.entities.findIndex(
          (existingContentModule) =>
            existingContentModule.id === contentModule.id,
        )

        if (existingContentModuleIndex > -1) {
          const existingContentModule =
            state.entities[existingContentModuleIndex]

          state.entities[existingContentModuleIndex] = {
            ...existingContentModule,
            meta: {
              ...existingContentModule.meta,
              whiteboard: {
                ...existingContentModule.meta.whiteboard,
                ...contentModule.payload,
              },
            },
          }
        }
      })
    },
  },
  extraReducers(builder) {
    builder
      .addCase('USER:LOGOUT', () => {
        return initialState
      })
      .addCase(fetchContentModules.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchContentModules.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const payload = action.payload as ContentModuleType[]
        state.entities = payload
      })
      .addCase(fetchContentModules.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.code || null
      })
      .addCase(deleteWhiteboardContentEntities.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const queryVariables = action.meta.arg.queryVariables
          const { deleteInput } = queryVariables

          const whiteboardContentContentModuleIdsToDelete = deleteInput.reduce<
            string[]
          >((acc, { entity, id }) => {
            if (entity === 'CONTENTMODULE') {
              acc.push(id)
            }
            return acc
          }, [])

          state.entities = state.entities.filter(
            ({ id }) => !whiteboardContentContentModuleIdsToDelete.includes(id),
          )
        }
      })
      .addCase(updateWhiteboardContentEntitiesMeta.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const updatedWhiteboardContentEntities =
            action.meta.arg.queryVariables.updateInput
          const updatedWhiteboardContentContentModuleEntities =
            updatedWhiteboardContentEntities.filter(
              ({ entity }) => entity === 'CONTENTMODULE',
            )

          updatedWhiteboardContentContentModuleEntities.forEach(
            (contentModule) => {
              const existingContentModuleIndex = state.entities.findIndex(
                (existingContentModule) =>
                  existingContentModule.id === contentModule.id,
              )

              if (existingContentModuleIndex > -1) {
                const existingContentModule =
                  state.entities[existingContentModuleIndex]

                state.entities[existingContentModuleIndex] = {
                  ...existingContentModule,
                  meta: {
                    ...existingContentModule.meta,
                    whiteboard: {
                      ...existingContentModule.meta.whiteboard,
                      ...contentModule.payload,
                    },
                  },
                }
              }
            },
          )
        }
      })
      .addCase(createContentModules.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const queryVariables = action.meta.arg.queryVariables
          const { contentModulesData } = queryVariables

          const newContentModules: ContentModuleType[] = contentModulesData.map(
            (contentModule) => ({
              ...contentModule,
              id: contentModule.cid!,
            }),
          )

          state.entities = [...state.entities, ...newContentModules]
        }
      })
      .addCase(createContentModules.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const newContentModules = action.payload
          const { contentModulesData } = action.meta.arg.queryVariables

          newContentModules.forEach((contentModule, index) => {
            const existingContentModuleIndex = state.entities.findIndex(
              (existingContentModule) =>
                existingContentModule.cid === contentModule.cid,
            )

            if (existingContentModuleIndex > -1) {
              const existingContentModule =
                state.entities[existingContentModuleIndex]
              const isPending =
                existingContentModule.cid === existingContentModule.id

              // keep base64 image for user who initiated the creation
              // to prevent flickering between base64 and image load
              const resolvedContentModule =
                contentModule?.type === 'IMAGE'
                  ? {
                      ...contentModule,
                      data: state.entities[existingContentModuleIndex].data,
                    }
                  : contentModule

              if (isPending) {
                state.entities[existingContentModuleIndex] =
                  resolvedContentModule
              }
            } else {
              // keep base64 image for user who initiated the creation
              // to prevent flickering between base64 and image load
              const resolvedContentModule =
                contentModule?.type === 'IMAGE'
                  ? { ...contentModule, data: contentModulesData[index].data }
                  : contentModule
              state.entities = [...state.entities, resolvedContentModule]
            }
          })
        }
      })
      .addCase(deleteContentModules.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const { ids } = action.meta.arg.queryVariables

          state.entities = state.entities.filter(({ id }) => !ids?.includes(id))
        }
      })
      .addCase(deleteContentModules.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const deletedContentModules = action.payload
          const deletedContentModulesIds = deletedContentModules.map(
            ({ id }) => id,
          )
          state.entities = state.entities.filter(
            ({ id }) => !deletedContentModulesIds?.includes(id),
          )
        }
      })
      .addCase(updateContentModules.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const { updateInput } = action.meta.arg.queryVariables

          updateInput?.forEach((contentModuleToUpdate) => {
            const existingContentModuleIndex = state.entities.findIndex(
              (contentModule) => contentModule.id === contentModuleToUpdate.id,
            )

            if (existingContentModuleIndex > -1) {
              const contentModule = state.entities[existingContentModuleIndex]
              state.entities[existingContentModuleIndex] = {
                ...contentModule,
                ...contentModuleToUpdate,
              }
            }
          })
        }
      })

      .addCase(updateContentModules.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const updatedContentModules = action.payload

          updatedContentModules?.forEach((contentModuleToUpdate) => {
            const existingContentModuleIndex = state.entities.findIndex(
              (contentModule) => contentModule.id === contentModuleToUpdate.id,
            )

            if (existingContentModuleIndex > -1) {
              const contentModule = state.entities[existingContentModuleIndex]
              state.entities[existingContentModuleIndex] = {
                ...contentModule,
                ...contentModuleToUpdate,
              }
            }
          })
        }
      })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useContentModulesDispatch: () => AppDispatch = useDispatch
export const useContentModulesSelector: TypedUseSelectorHook<RootState> =
  useSelector

// Action creators are generated for each case reducer function
export const {
  wsDeleteContentModules,
  wsUpdateContentModules,
  wsCreateContentModules,
  wsUpdateWhiteboardContentContentModulesEntity,
} = contentModulesSlice.actions

export default contentModulesSlice.reducer
