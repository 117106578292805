/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, operator-linebreak */

import { useState } from 'react'
import clsx from 'clsx'
import type { Icons } from '@sceneio/ui-icons'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import type {
  DataTableHeaderProps,
  DataTableSortingType,
} from '../dataTableTypes'
import { resolveHeadAttributeValue } from '../dataTableUtils'
import { RawModal } from '@sceneio/components-modal'
import { Icon } from '@sceneio/ui-icons'

const DEFAULT_FILTER_TOOLTIP = 'Apply filter'

function FilterIcon({
  tooltip = DEFAULT_FILTER_TOOLTIP,
  onClick = () => null,
  active,
}: {
  tooltip?: string
  onClick: () => any
  active?: boolean
}) {
  // defaults
  const textColor = active ? 'text-primary' : 'text-secondary'

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="data-table-col-filter-btn">{tooltip}</Tooltip>}
    >
      <Button
        onClick={onClick}
        variant="transparent"
        size="sm"
        style={{ fontSize: '75%' }}
        className={`${textColor} px-1 py-0`}
      >
        <Icon provider="phosphor" icon="Funnel" />
      </Button>
    </OverlayTrigger>
  )
}

function SortIcon({
  col,
  sortingCol,
  sortingDirection,
}: {
  col: string
  sortingCol: DataTableSortingType['col']
  sortingDirection: DataTableSortingType['direction']
}) {
  // defaults
  let icon: Icons['phosphor'] = 'CaretUpDown'
  let textColor = 'text-muted'

  // used state
  if (col === sortingCol) {
    textColor = 'text-danger'
    icon =
      String(sortingDirection).toLowerCase() === 'asc' ? 'CaretUp' : 'CaretDown'
  }

  return (
    <span className={`${textColor} px-1`}>
      <Icon provider="phosphor" icon={icon} />
    </span>
  )
}

const FILTER_MODAL_INITIAL_STATE: {
  show: boolean
  filterRenderer: () => any
  tooltip: string
} = {
  show: false,
  tooltip: '',
  filterRenderer: () => null,
} as const

export function DataTableHead<DataItem>({
  cols,
  isTableSortable,
  isTableFilterable,
  filters: { colFilters = {}, collapsible = {} } = {},
  onSortChange = () => {},
  sorting: { col: sortingCol, direction: sortingDirection },
}: DataTableHeaderProps<DataItem>) {
  const [filterModal, setFilterModal] = useState(FILTER_MODAL_INITIAL_STATE)

  const thItems = Object.keys(cols).map((col) => {
    const headClasses = clsx(
      resolveHeadAttributeValue('headClassName', '', cols[col]),
    )

    const headStyle = resolveHeadAttributeValue('headStyle', {}, cols[col])
    const isSortable = isTableSortable && cols[col].sortable
    const isFilterable = isTableFilterable && col in colFilters
    const isCollapsible = col in collapsible || {}
    const onSortClick = isSortable ? () => onSortChange(col) : () => {}
    const headChildClasses = clsx('d-flex align-items-center', {
      'cursor-pointer': isSortable,
    })
    return (
      <th
        className={headClasses}
        style={headStyle}
        scope="col"
        key={`th-${col}`}
      >
        <span className={headChildClasses} onClick={onSortClick}>
          {isCollapsible && collapsible[col]?.render()}
          {cols[col].label}{' '}
          {isSortable && (
            <SortIcon
              col={col}
              sortingCol={sortingCol}
              sortingDirection={sortingDirection}
            />
          )}
          {isFilterable && (
            <FilterIcon
              active={colFilters[col].isFilterApplied}
              onClick={() =>
                setFilterModal({
                  tooltip: colFilters[col].tooltip || DEFAULT_FILTER_TOOLTIP,
                  show: true,
                  filterRenderer: colFilters[col].render,
                })
              }
              tooltip={colFilters[col].tooltip}
            />
          )}
        </span>
      </th>
    )
  })
  return (
    <tr>
      {thItems}
      {isTableFilterable && (
        <RawModal
          show={filterModal.show}
          title={filterModal.tooltip.toUpperCase()}
          hideButtons
          centered
          onClose={() => setFilterModal(FILTER_MODAL_INITIAL_STATE)}
        >
          {filterModal.filterRenderer()}
        </RawModal>
      )}
    </tr>
  )
}
