import React from 'react'
import clsx from 'clsx'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import Image from '../Image/Image'
import { getVideoType } from './videoUtils'

import { BreakpointEnum } from '../Image/constants/breakpoints'
import { resolveBreakpointImageClasses } from '../Image/helpers/resolveBreakpointImageClasses'
import { resolveDefaultImageClasses } from '../Image/helpers/resolveDefaultImageClasses'
import Text from '../Text/Text'
import { VideoComponent } from '@sceneio/schemas/lib/types'
import { WebIcon } from '../WebIcon/WebIcon'

const playerConfigFactory = ({
  showControls,
}: {
  showControls: boolean
}): Record<string, any> => ({
  youtube: {
    playerVars: {
      showinfo: 0,
      controls: showControls ? 1 : 0,
      autohide: 1,
    },
  },
  file: {
    attributes: {
      controls: showControls,
    },
  },
})

function BackgroundVideo({
  src,
  format,
  className,
  preview,
}: {
  src: string
  className?: string
  format: VideoComponent['format']
  preview: VideoComponent['preview']
}): JSX.Element {
  const videoBackgroundClass = clsx(
    'sc-video__video sc-video__video--background',
    className,
  )

  return (
    <video
      className={videoBackgroundClass}
      data-keepplaying
      playsInline
      onMouseOver={(event) => {
        if (preview) {
          event.currentTarget.play()
        }
      }}
      onMouseOut={(event) => {
        if (preview) {
          event.currentTarget.pause()
        }
      }}
      autoPlay={preview ? false : true}
      muted
      loop
    >
      <source src={src} type={getVideoType(format)} />
    </video>
  )
}

const Video = ({
  responsiveConfig,
  isBackgroundVideo,
  showPlayButton = true,
  showControls = true,
  showPoster = true,
  usePlayer = true,
  playButton,
  className,
  format,
  language = 'en',
  show = true,
  cover,
  style,
  duration,
  preview,
}: VideoComponent) => {
  if (!responsiveConfig || !Object.keys(responsiveConfig).length || !show) {
    return null
  }

  return (
    <>
      {Object.entries(responsiveConfig).map(([key, videoBreakpoint]) => {
        const {
          src,
          posterImageSrc,
          posterImageAlt,
          posterImageClasses,
          posterImageHeight,
          posterImageWidth,
          posterImageId,
          posterVideoSrc,
          posterType = 'image',
        } = videoBreakpoint || {}

        if (!src) {
          return null
        }

        const videoClass = clsx(
          'sc-video',
          {
            'sc-video--cover': cover,
            [resolveBreakpointImageClasses({
              elementClassName: 'sc-video',
              breakpoint: key as BreakpointEnum,
            })]: key !== 'default',
            [resolveDefaultImageClasses({
              elementClassName: 'sc-video',
              breakpointsArr: (
                Object.keys(responsiveConfig) as BreakpointEnum[]
              ).filter((bp) => responsiveConfig[bp]?.src),
            })]: key === 'default',
          },
          className,
        )

        if (isBackgroundVideo) {
          return (
            <div
              key={videoBreakpoint}
              className={clsx(`sc-video--background ${videoClass}`)}
            >
              <BackgroundVideo src={src} format={format} preview={preview} />
            </div>
          )
        }

        if (usePlayer) {
          return (
            <div
              key={videoBreakpoint}
              data-plugin-video={JSON.stringify({
                url: src,
                controls: showControls,
                playerConfig: playerConfigFactory({ showControls }),
              })}
              className={videoClass}
              style={{
                ...style,
                ...getRootLevelCssVariables({
                  cssVariables: {
                    playButton,
                  },
                  prefix: 'video',
                }),
              }}
            >
              {showPoster && (
                <>
                  {posterType === 'image' && (
                    <Image
                      responsiveConfig={{
                        default: {
                          src: posterImageSrc,
                          alt: posterImageAlt,
                          width: posterImageWidth,
                          height: posterImageHeight,
                        },
                      }}
                      className={clsx('sc-video__poster', posterImageClasses)}
                      id={posterImageId}
                    />
                  )}
                  {posterType === 'video' && (
                    <BackgroundVideo
                      className="sc-video__video--poster"
                      src={posterVideoSrc || src}
                      format={format}
                      preview={preview}
                    />
                  )}
                </>
              )}
              {showPlayButton && (
                <div
                  className="sc-video__play-button"
                  data-plugin-video-play-button
                >
                  <WebIcon icon="PlayCircle" />
                </div>
              )}
              <Text
                {...duration}
                as="div"
                dataAttributes={{
                  'data-plugin-video-duration': JSON.stringify({
                    language,
                    round: true,
                  }),
                }}
                children="&nbsp;"
                className="sc-video__duration"
              />
              <div data-plugin-video-content className="sc-video__player" />
            </div>
          )
        }

        return (
          <div className={videoClass} key={videoBreakpoint}>
            <video
              className="sc-video__video"
              playsInline
              poster={showPoster ? posterImageSrc : undefined}
              controls
            >
              <source src={src} type={getVideoType(format)} />
            </video>
          </div>
        )
      })}
    </>
  )
}

export const plugins = {
  js: ['video'],
  css: [],
}
export default Video
