import { useState } from 'react'
import { cacheFactory } from '@sceneio/localstorage-cache'
import pkg from '../../../package.json'

const cache = cacheFactory(`usePersistentState:${pkg.version}`)

function resolveValue(value: any, defaultValue?: any) {
  // TODO @gabo - is "value(defaultValue)" ok?
  return value instanceof Function ? value(defaultValue) : value
}

export function usePersistentState<V>(key: string, value: V) {
  if (!key) throw Error('[usePersistentState] Key is required!')

  type Value = V extends (...args: any[]) => infer R ? R : V

  const [storedValue, setStoredValue] = useState<Value>(() => {
    try {
      return cache.get(key) || resolveValue(value)
    } catch (error: any) {
      console.warn('[usePersistentState]', error?.message)
      return resolveValue(value)
    }
  })

  const setValue = (prop: Value) => {
    try {
      const valueToStore = resolveValue(prop, storedValue)
      setStoredValue(valueToStore)
      cache.set(key, valueToStore)
    } catch (error: any) {
      console.warn('[usePersistentState]', error?.message)
    }
  }

  return [storedValue, setValue] as const
}
