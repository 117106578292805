import { camelCase } from 'change-case'
import type {
  DataTableColItem,
  DataTableColItemPropWithPossibleCallback,
  DataTablePagination,
  DataTableSortingType,
} from './dataTableTypes'

export function resolveColAttributeValue<DataItem, T>(
  attributeName: DataTableColItemPropWithPossibleCallback,
  defaultValue: T,
  col: DataTableColItem<DataItem>,
  item: DataItem,
): T {
  if (!(attributeName in col)) {
    return defaultValue
  }

  return typeof col?.[attributeName] === 'function'
    ? (col[attributeName] as Function)(item)
    : col[attributeName]
}

export function resolveHeadAttributeValue<DataItem, T>(
  attributeName: keyof DataTableColItem<DataItem>,
  defaultValue: T,
  col: DataTableColItem<DataItem>,
): T {
  if (!col[attributeName]) return defaultValue
  return typeof col[attributeName] === 'function'
    ? (col[attributeName] as Function)()
    : col[attributeName]
}

export function calculatePaginator(
  length: number,
  currentPage: number,
  itemsPerPage: number,
): DataTablePagination {
  return {
    total: length,
    itemsPerPage,
    currentPage,
    lastPage: Math.ceil(length / itemsPerPage),
    from: (currentPage - 1) * itemsPerPage + 1,
    to: currentPage * itemsPerPage,
  }
}

export function createOrderByDefinition(field: string): string {
  return camelCase(`orderBy ${field}`)
}

export function buildQueryVariables(
  initialVariables: Record<string, unknown>,
  sorting: DataTableSortingType,
  itemsPerPage: number,
): {} {
  const variables = { ...initialVariables }

  if (itemsPerPage) {
    variables.first = itemsPerPage
  }

  if (sorting.col) {
    variables[createOrderByDefinition(sorting.col)] = sorting.direction
  }

  return variables
}

export function allowResizingColumns() {
  // Code By Webdevtrick ( https://webdevtrick.com )
  const min = 80
  // The max (fr) values for grid-template-columns

  interface ColumnTypeToRatioMapType {
    [key: string]: string | number | undefined
  }

  const columnTypeToRatioMap: ColumnTypeToRatioMapType = {
    numeric: 1,
    'text-short': 1.67,
    'text-long': 3.33,
  }

  const table = document.querySelector('table')

  const columns: {
    header: HTMLTableCellElement
    // The initial size value for grid-template-columns:
    size: string
  }[] = []
  let headerBeingResized: HTMLTableCellElement | null

  // The next three functions are mouse event callbacks

  // Where the magic happens. I.e. when they're actually resizing
  const onMouseMove = (e: any) =>
    requestAnimationFrame(() => {
      console.log('onMouseMove')

      // Calculate the desired width
      const horizontalScrollOffset = document.documentElement.scrollLeft
      const width =
        headerBeingResized &&
        horizontalScrollOffset + e.clientX - headerBeingResized.offsetLeft

      // Update the column object with the new size value
      const column = columns.find(({ header }) => header === headerBeingResized)
      if (column && width) {
        column.size = `${Math.max(min, width)}px` // Enforce our minimum
      }

      // For the other headers which don't have a set width, fix it to their computed width
      columns.forEach((col) => {
        if (col.size.startsWith('minmax')) {
          // isn't fixed yet (it would be a pixel value otherwise)
          // eslint-disable-next-line no-param-reassign
          col.size = `${parseInt(String(col.header.clientWidth), 10)}px`
        }
      })

      /*
          Update the column sizes
          Reminder: grid-template-columns sets the width for all columns in one value
        */
      if (table) {
        table.style.gridTemplateColumns = columns
          .map(({ size }) => size)
          .join(' ')
      }
    })

  // Clean up event listeners, classes, etc.
  const onMouseUp = () => {
    console.log('onMouseUp')

    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
    if (headerBeingResized)
      headerBeingResized.classList.remove('header--being-resized')
    headerBeingResized = null
  }

  // Get ready, they're about to resize
  const initResize = ({ target }: any) => {
    console.log('initResize')

    headerBeingResized = target.parentNode
    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
    if (headerBeingResized)
      headerBeingResized.classList.add('header--being-resized')
  }

  // Let's populate that columns array and add listeners to the resize handles
  document.querySelectorAll('th').forEach((header) => {
    const max =
      header.dataset.type && `${columnTypeToRatioMap[header.dataset.type]}fr`
    columns.push({
      header,
      // The initial size value for grid-template-columns:
      size: `minmax(${min}px, ${max})`,
    })

    header
      .querySelector('.resize-handle')
      ?.addEventListener('mousedown', initResize)
  })
}
