import {
  DataTableColInternalItemWithCallback,
  DataTableColItem,
  DataTableProps,
} from '../dataTableTypes'
import { DataTableColsManager } from './DataTableColsManager'

type DataTableHeaderProps = {
  title?: string | JSX.Element
  description?: string | JSX.Element
  enableColsManager?: DataTableProps<Record<string, any>>['enableColsManager']
  colsWithCallbacks: DataTableColInternalItemWithCallback[]
  mergedColsDefinition: { [x: string]: DataTableColItem<any> }
  isSomeColStatusChanged: boolean
  tableSearch: DataTableProps<Record<string, any>>['tableSearch']
  appendAdditionalSettings: DataTableProps<
    Record<string, any>
  >['appendAdditionalSettings']
}

export const DataTableHeader = ({
  title,
  description,
  enableColsManager,
  colsWithCallbacks,
  mergedColsDefinition,
  isSomeColStatusChanged,
  tableSearch,
  appendAdditionalSettings,
}: DataTableHeaderProps) => {
  if (
    !title &&
    !description &&
    !enableColsManager &&
    !tableSearch &&
    !appendAdditionalSettings
  )
    return null

  return (
    <div className="data-table__header d-flex align-items-center justify-content-between flex-wrap gap-2">
      <div>
        {title && <h6 className="mb-0">{title}</h6>}
        {description && (
          <p className="mb-0 mt-2 text-secondary">{description}</p>
        )}
      </div>
      <div className="d-flex align-items-center gap-2 ms-auto">
        {tableSearch && tableSearch}
        {enableColsManager && (
          <DataTableColsManager
            managedCols={colsWithCallbacks}
            givenCols={mergedColsDefinition}
            isSomeColStatusChanged={isSomeColStatusChanged}
          />
        )}
        {appendAdditionalSettings && appendAdditionalSettings}
      </div>
    </div>
  )
}
