import { randomString } from '@sceneio/tools'
import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'

export const videoComponentMeta: ComponentMeta<'video'> = {
  defaultConfig: {
    meta: {
      dataType: 'video-component',
    },
    language: 'en',
    showPoster: true,
    showPlayButton: true,
    showControls: true,
    responsiveConfig: {
      default: {
        posterType: 'image',
        src: 'https://cdn.scene.io/static/assets/videos/placeholders/video-sample.mp4',
        posterImageSrc:
          'https://assets.scene.io/global/placeholders/light_1400x1080.png',
        posterVideoSrc:
          'https://cdn.scene.io/static/assets/videos/placeholders/video-sample.mp4',
      },
    },
    className: '',
    show: true,
    duration: {
      show: true,
      meta: {
        dataType: 'text-component',
      },
      font: {
        meta: {
          dataType: 'font-style',
        },
        data: {
          default: {
            '--font-default-family-default': 'arial, sans-serif',
          },
        },
      },
      margin: {
        data: {
          '--margin-default-top': '80px',
        },
      },
      textShadow: {
        meta: {
          dataType: 'text-shadow-style',
        },
        data: {
          responsiveConfig: {
            default: {
              default: [
                {
                  id: randomString(8),
                  x: '1px',
                  y: '1px',
                  blur: '3px',
                  color: 'rgba(0,0,0,0.3)',
                },
              ],
            },
          },
        },
      },
      color: {
        data: {
          default: {
            '--color-default-default': '#fff',
          },
        },
      },
    },
  },
}

export const videoDefaults = componentDefaultConfigFactory<'video'>({
  componentMeta: videoComponentMeta,
})
