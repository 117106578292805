import { OverlayStyle } from '@sceneio/schemas/lib/types'
import { mergeDeepLeft } from '@sceneio/tools'

export type UsedResponsiveBreakpoints = Array<'default' | 'xs' | 'md' | 'lg'>

export function getOverlayCssVariables({
  overlay,
  type,
  usedResponsiveBreakpoints,
}: {
  overlay?: OverlayStyle
  type: string
  usedResponsiveBreakpoints: UsedResponsiveBreakpoints
}) {
  const { responsiveConfig } = overlay || {}

  if (responsiveConfig) {
    const mergedResponsiveConfig = usedResponsiveBreakpoints.reduce(
      (acc, val) => {
        if (val !== 'default') {
          acc = mergeDeepLeft(acc, {
            [val]: '',
          })
        }
        return acc
      },
      responsiveConfig,
    )
    return Object.entries(mergedResponsiveConfig).reduce(
      (acc, [bp, config]) => ({
        ...acc,
        ...(config && {
          [`--${type}-overlay-${bp}`]: config,
        }),
        ...(!config &&
          bp !== 'default' && {
            [`--${type}-overlay-${bp}`]: 'transparent',
          }),
      }),
      {},
    )
  }
}
