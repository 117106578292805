import clsx from 'clsx'
import type { DataTableProps } from './dataTableTypes'
import { DataTableHead } from './components/DataTableHead'
import { DataTableBody } from './components/DataTableBody'
import { DataTablePaginator } from './components/DataTablePaginator'
import { useDataTable } from './hooks/useDataTable'
import { useDataTableColState } from './hooks/useDataTableColState'
import styles from '../lib/styles/dataTable.scss?inline'
import { DataTableHeader } from './components/DataTableHeader'

export function DataTable<DataItem extends Record<string, any>>({
  name,
  title,
  description,
  idKey = 'id',
  data = [],
  cols,
  row,
  prependAdditionalCols = {},
  appendAdditionalCols = {},
  appendAdditionalSettings,
  wrapperClassName,
  tableClassName,
  responsive = true,
  itemsPerPage = 1000,
  enableColsManager = true,
  defaultHiddenCols = [],
  sortable = true,
  filterable = false,
  initialSorting = {},
  showTableHead = true,
  showTopPaginator = false,
  topPaginatorSize = 'default',
  topPaginatorPosition = 'center',
  showBottomPaginator = true,
  bottomPaginatorSize = 'default',
  bottomPaginatorPosition = 'center',
  emptyDataMessage,
  filters = {},
  useDefaultCss = true,
  tableSearch,
}: DataTableProps<DataItem>) {
  const mergedColsDefinition = {
    ...prependAdditionalCols,
    ...cols,
    ...appendAdditionalCols,
  }

  const { tableData, paginator, onPageChange, onSortChange, sorting } =
    useDataTable({
      data,
      initialPage: 1,
      itemsPerPage,
      tableName: name,
      initialSorting,
    })

  const {
    visibleCols,
    colsWithCallbacks,
    isSomethingChanged: isSomeColStatusChanged,
  } = useDataTableColState({
    cols: mergedColsDefinition,
    tableName: name,
    hiddenCols: defaultHiddenCols,
  })

  const wrapperClasses = clsx('data-table', wrapperClassName)
  const tableClasses = clsx(
    {
      'table-responsive': responsive,
    },
    tableClassName,
  )

  if (!name) throw Error('DataTable "name" not specified!')

  if (data.length === 0) {
    return (
      <>
        {useDefaultCss && <style>{styles}</style>}
        <div className={wrapperClasses}>
          <DataTableHeader
            title={title}
            description={description}
            enableColsManager={enableColsManager}
            colsWithCallbacks={colsWithCallbacks}
            mergedColsDefinition={mergedColsDefinition}
            isSomeColStatusChanged={isSomeColStatusChanged}
            tableSearch={tableSearch}
            appendAdditionalSettings={appendAdditionalSettings}
          />
          {emptyDataMessage === undefined ? (
            <div className="p-3">No data provided.</div>
          ) : (
            <div className="p-3">{emptyDataMessage}</div>
          )}
        </div>
      </>
    )
  }

  if (data[0][idKey] === undefined) {
    return (
      <>
        {useDefaultCss && <style>{styles}</style>}
        <div className={wrapperClasses}>
          <DataTableHeader
            title={title}
            description={description}
            enableColsManager={enableColsManager}
            colsWithCallbacks={colsWithCallbacks}
            mergedColsDefinition={mergedColsDefinition}
            isSomeColStatusChanged={isSomeColStatusChanged}
            tableSearch={tableSearch}
            appendAdditionalSettings={appendAdditionalSettings}
          />
          <div className="p-3">
            Property <code>{idKey}</code> not exists.
          </div>
        </div>
      </>
    )
  }

  const { col: sortingCol = '', direction: sortingDirection = '' } =
    initialSorting

  if (sortingCol && data[0][sortingCol] === undefined) {
    throw Error(`Sorting column "${sortingCol}" not exists!`)
  }

  if (sortingCol && !['asc', 'desc'].includes(sortingDirection.toLowerCase())) {
    throw Error(
      `Sorting supports only "asc" | "desc" direction. "${sortingDirection}" given.`,
    )
  }

  const showRegularPaginator =
    'lastPage' in paginator && paginator.lastPage !== 1

  return (
    <>
      {useDefaultCss && <style>{styles}</style>}
      <div className={wrapperClasses}>
        <DataTableHeader
          title={title}
          description={description}
          enableColsManager={enableColsManager}
          colsWithCallbacks={colsWithCallbacks}
          mergedColsDefinition={mergedColsDefinition}
          isSomeColStatusChanged={isSomeColStatusChanged}
          tableSearch={tableSearch}
          appendAdditionalSettings={appendAdditionalSettings}
        />
        {/* TOP PAGINATOR */}
        {showRegularPaginator && showTopPaginator && (
          <DataTablePaginator
            position={topPaginatorPosition}
            size={topPaginatorSize}
            pagination={paginator}
            onChange={onPageChange}
          />
        )}

        {/* TABLE  */}
        <div className={tableClasses}>
          <table className="table">
            {showTableHead && (
              <thead className="data-table__head">
                <DataTableHead
                  cols={visibleCols}
                  isTableSortable={sortable}
                  onSortChange={onSortChange}
                  sorting={sorting}
                  isTableFilterable={filterable}
                  filters={filters}
                />
              </thead>
            )}
            <tbody>
              <DataTableBody
                data={tableData}
                cols={visibleCols}
                idKey={idKey}
                row={row}
              />
            </tbody>
          </table>
        </div>

        {/* BOTTOM PAGINATOR */}
        {showRegularPaginator && showBottomPaginator && (
          <DataTablePaginator
            position={bottomPaginatorPosition}
            size={bottomPaginatorSize}
            pagination={paginator}
            onChange={onPageChange}
          />
        )}
      </div>
    </>
  )
}
