import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Text from '../../../components/Text/Text'
import Button from '../../../components/Button/Button'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { Container } from '../../../components/Container/Container'
import { buttonSampleFactory, ctaBanner3DefaultConfig } from './CTABanner3Meta'
import { CTABanner3Block } from '@sceneio/schemas/lib/types'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'

function CTABanner3({
  id,
  components: { block, tagline, heading, text, buttons },
  editor,
}: CTABanner3Block = ctaBanner3DefaultConfig) {
  const { className, swapOrder, columnRatio, ...restBlockProps } = block

  const leftColumns = columnRatio!
  const rightColumns = 12 - leftColumns

  const ctaBanner3Class = clsx(['sc-cta-banner-3', className])

  const leftColumnClasses = clsx('sc-cta-banner-3__left-column', {
    [`bui-col-md-${leftColumns}`]: leftColumns,
    'bui-order-md-last': swapOrder,
  })

  const rightColumnClasses = clsx('sc-cta-banner-3__right-column', {
    [`bui-col-md-${rightColumns}`]: rightColumns,
    [`bui-col`]: rightColumns === 0,
  })

  const showButtons = Boolean(
    buttons?.items?.length || (0 > 0 && buttons?.show),
  )

  return (
    <Block
      className={ctaBanner3Class}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <div className="bui-row">
        <div className={leftColumnClasses}>
          <Text
            {...tagline}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'tagline',
            }}
            cid={resolveComponentId(id, 'tagline')}
          />
          <Text
            {...heading}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'heading',
            }}
            cid={resolveComponentId(id, 'heading')}
          />
        </div>
        <div className={rightColumnClasses}>
          <Text
            {...text}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'text',
            }}
            cid={resolveComponentId(id, 'text')}
          />
          <Container
            className="bui-flex-wrap bui-w-100"
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'buttons',
            }}
            {...buttons}
            name="componentArray"
            show={showButtons}
          >
            {buttons?.items?.map(
              (
                {
                  id: buttonId,
                  childrenWrapProps = {},
                  className: buttonClassname,
                  ...restButtonProps
                },
                index,
              ) => (
                <Button
                  editor={{
                    ...editor?.component,
                    blockId: id,
                    id: `buttons.items[${index}]`,
                    wysiwyg: {
                      type: 'ITEMS',
                      ...editorItemsControlsFactory({
                        itemsArr: buttons.items || [],
                        itemsId: `buttons.items`,
                        itemFactory: buttonSampleFactory,
                        index: index,
                      }),
                    },
                  }}
                  className={clsx('sc-split-hero__button', buttonClassname)}
                  childrenWrapProps={{
                    ...childrenWrapProps,
                  }}
                  key={buttonId}
                  cid={resolveComponentId(id, `button-${index}`)}
                  {...restButtonProps}
                />
              ),
            )}
          </Container>
        </div>
      </div>
    </Block>
  )
}

export const ctaBanner3Plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default CTABanner3
