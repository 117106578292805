import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { randomString } from '@sceneio/tools'
import { cardDefaults } from '../../../components/Card/cardMeta'
import type {
  CardComponent,
  FeatureGrid4Block,
} from '@sceneio/schemas/lib/types'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../../Hero/SplitHero/form/additionalFormFieldRenderers'

const { border, ...CARD_DEFAULTS } = cardDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const buttonSampleFactory = () => ({
  meta: {
    dataType: 'button-component',
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
  id: randomString(8),
  children: textToLexical('Button', 'array-item'),
  link: {
    type: 'external',
    href: '#',
    target: '_self',
  },
})

export const cardItemSampleFactory = (index?: number): CardComponent => ({
  meta: {
    dataType: 'card-component',
  },
  id: randomString(),
  gap: {
    data: {
      '--row-gap-default': '0rem',
      '--column-gap-default': '0rem',
    },
  },
  border: {
    meta: {
      dataType: 'border-style',
    },
    data: {
      default: {
        '--border-default-radius-top-left-default': '12px',
        '--border-default-radius-top-right-default': '12px',
        '--border-default-radius-bottom-left-default': '12px',
        '--border-default-radius-bottom-right-default': '12px',
      },
    },
  },
  padding: {
    data: {
      '--padding-default-top': '3rem',
      '--padding-default-left': '3rem',
      '--padding-default-right': '3rem',
      '--padding-default-bottom': '3rem',
    },
  },
  background: {
    responsiveConfig: {
      default: {
        color: '#F4F4F4',
      },
    },
  },
  components: {
    ...CARD_DEFAULTS.components,
    media: {
      meta: {
        dataType: 'media-component',
      },
      show: false,
      format: 'image',
      components: {
        ...CARD_DEFAULTS.components.media.components,
        image: {
          meta: {
            dataType: 'image-component',
          },
          responsiveConfig: {
            default: {
              src: 'https://assets.scene.io/global/placeholders/check-circle.svg',
            },
          },
        },
      },
    },
    tagline: {
      ...CARD_DEFAULTS.components.tagline,
      show: false,
    },
    heading: {
      ...CARD_DEFAULTS.components.heading,
      children: textToLexical(index ? `Feature #${index}` : 'Feature', 'h3'),
    },
    buttons: {
      ...CARD_DEFAULTS.components.buttons,
      show: false,
    },
    contentContainer: {
      ...CARD_DEFAULTS.components.contentContainer,
      padding: {
        data: {
          '--padding-default-left': 0,
          '--padding-default-right': 0,
          '--padding-default-top': '2rem',
        },
      },
    },
  },
})

export const featureGrid4ComponentMeta: BlockComponentMeta<FeatureGrid4Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            rowGap: '1rem',
            maxWidth: 'screen',
            columnGap: '1.5rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: BLOCK_CONTAINER_DEFAULTS.padding,
      },
      type: 'blockContainer',
    },
    tagline: {
      path: 'components.tagline',
      name: 'Tagline',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.tagline',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        children: textToLexical('Tagline'),
        as: 'p',
        show: true,
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
      },
      type: 'text',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical('Short heading goes here', 'h2'),
        as: 'h2',
        show: true,
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        ),
        as: 'p',
        show: true,
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        margin: {
          data: {
            '--margin-default-top': 0,
            '--margin-default-left': 0,
            '--margin-default-right': 0,
            '--margin-default-bottom': '3rem',
          },
        },
      },
      type: 'text',
    },
    cards: {
      path: 'components.cards',
      name: 'Cards',
      type: 'grid',
      disableToggleShow: true,
      components: {
        items: {
          isArrayComponent: true,
          sampleFactory: cardItemSampleFactory,
          path: 'items',
          name: 'Card',
          type: 'card',
          disableToggleShow: true,
          components: {
            media: {
              type: 'media',
              name: 'Media',
              path: 'components.media',
              components: {
                credits: {
                  path: 'components.credits',
                  inlineEditors: [
                    {
                      editorType: 'inlineText',
                      editorPath: 'components.media.components.credits',
                      editorOptions: {
                        isNestedArrayItem: true,
                      },
                    },
                  ],
                  disableEditing: true,
                  name: 'Credits',
                  type: 'text',
                },
              },
            },
            contentContainer: {
              type: 'container',
              name: 'Content container',
              path: 'components.contentContainer',
              configEditor: {
                formProps: {
                  name: 'group',
                  registerValues:
                    containerComponentFormRegisterValuesFactory('group'),
                },
              },
            },
            tagline: {
              type: 'text',
              name: 'Tagline',
              path: 'components.tagline',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.tagline',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            heading: {
              type: 'text',
              name: 'Heading',
              path: 'components.heading',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.heading',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            description: {
              type: 'text',
              name: 'Description',
              path: 'components.description',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.description',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            buttons: {
              type: 'container',
              name: 'Buttons',
              path: 'components.buttons',
              components: {
                items: {
                  isArrayComponent: true,
                  path: 'items',
                  name: 'Button',
                  type: 'button',
                  disableToggleShow: true,
                  sampleFactory: () => buttonSampleFactory(),
                },
              },
              inlineEditors: [
                {
                  editorType: 'buttonEditor',
                  editorPath: 'components.buttons.items',
                  editorOptions: {
                    isNestedArrayItem: true,
                    isItemArray: true,
                    sampleFactory: () => buttonSampleFactory(),
                  },
                },
              ],
              configEditor: {
                additionalFormFields: {
                  layout: {
                    formFieldRenderer:
                      buttonContainerLayoutAdditionalFormFieldRenderer,
                  },
                },
                formProps: {
                  name: 'componentArray',
                  registerValues:
                    containerComponentFormRegisterValuesFactory(
                      'componentArray',
                    ),
                },
              },
            },
          },
        },
      },
      defaultConfig: {
        meta: {
          dataType: 'grid-component',
        },
        items: [
          cardItemSampleFactory(1),
          cardItemSampleFactory(2),
          cardItemSampleFactory(3),
          cardItemSampleFactory(4),
          cardItemSampleFactory(5),
          cardItemSampleFactory(6),
        ],
        gap: {
          data: {
            [`--row-gap-default`]: '2rem',
            [`--column-gap-default`]: '2rem',
          },
        },
        columns: {
          data: {
            '--columns-default': 3,
            '--columns-md': 2,
            '--columns-xs': 1,
          },
        },
      },
    },
  }

export const featureGrid4BlockMeta: BlockMeta<FeatureGrid4Block> = {}

export const featureGrid4DefaultConfig =
  blockDefaultConfigFactory<FeatureGrid4Block>({
    componentMeta: featureGrid4ComponentMeta,
    blockMeta: featureGrid4BlockMeta,
    version: 1,
  })
