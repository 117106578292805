import type { ActionWithPayload, AclState } from '../types'
import { ACL, USER } from '../constatnts'

export const aclInitialState: AclState = {
  allowedAclResources: [],
}

export default function aclReducer(
  state: AclState = aclInitialState,
  { type, payload }: ActionWithPayload<AclState>,
): AclState {
  if (type === ACL.ACL_FETCH_SUCCESS) {
    return {
      ...state,
      ...payload,
    }
  }

  if (type === USER.LOGOUT) {
    return aclInitialState
  }

  return state
}
