import { useMemo } from 'react'
import clsx from 'clsx'
import type { DataTablePaginatorProps } from '../dataTableTypes'

type ItemProps = {
  label: string | number
  pageNumber: number
  isDisabled: boolean
  onChange: (prop: number) => void
}

function Item(props: ItemProps) {
  const { label, pageNumber, isDisabled = false, onChange } = props
  return (
    <li className={`page-item${isDisabled ? ' disabled' : ''}`}>
      <button
        type="button"
        className="page-link"
        onClick={() => onChange(pageNumber)}
      >
        {label}
      </button>
    </li>
  )
}

export function DataTablePaginator({
  pagination,
  onChange,
  size = 'default',
  position = 'center',
}: DataTablePaginatorProps) {
  const { currentPage, lastPage } = pagination
  const NumberedItems = [...Array(lastPage).keys()].map((_, index) => {
    const iterator = index + 1
    return (
      <Item
        onChange={onChange}
        key={iterator}
        label={iterator}
        pageNumber={iterator}
        isDisabled={currentPage === iterator}
      />
    )
  })

  // use-case itemsPerPage = Infinity
  if (!lastPage) {
    return null
  }

  const paginatorClasses = useMemo(
    () =>
      clsx('flex-wrap', {
        pagination: true,
        'pagination-lg': size === 'big',
        'pagination-sm': size === 'small',
        'justify-content-center': position === 'center',
        'justify-content-end': position === 'right',
      }),
    [position, size],
  )
  return (
    <>
      <nav>
        <ul className={paginatorClasses}>
          <Item
            onChange={onChange}
            label="Previous"
            pageNumber={currentPage - 1}
            isDisabled={currentPage === 1}
          />
          {NumberedItems}
          <Item
            onChange={onChange}
            label="Next"
            pageNumber={currentPage + 1}
            isDisabled={currentPage === lastPage}
          />
        </ul>
      </nav>
    </>
  )
}
