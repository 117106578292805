import { useMemo } from 'react'
import { usePersistentState } from '@sceneio/hooks/lib/usePersistentState'
import type {
  HookUseDataTableColState,
  DataTableColInternalItem,
  DataTableColInternalItemWithCallback,
} from '../dataTableTypes'
import { stringify, arraysSymetricDifference } from '@sceneio/tools'

function getOnlyHiddenColKeys(
  cols: (DataTableColInternalItemWithCallback | DataTableColInternalItem)[],
) {
  return cols.filter((item) => item.status === false).map((item) => item.key)
}

export function useDataTableColState<Cols extends Record<string, any>>({
  tableName,
  cols,
  hiddenCols = [],
}: HookUseDataTableColState<Cols>) {
  const [colsState, setColsState] = usePersistentState(
    `dataTable:${tableName}:cols`,
    (): DataTableColInternalItem[] => {
      const colsWithDefaults = Object.keys(cols).map((item) => ({
        key: item,
        status: !hiddenCols.includes(item),
      }))

      return colsWithDefaults
    },
  )

  const colsWithCallbacks: Array<DataTableColInternalItemWithCallback> =
    useMemo(
      () =>
        colsState.map((item, index) => ({
          ...item,
          onChange: () => {
            const newState = [...colsState]
            newState[index].status = !newState[index].status
            setColsState(newState)
          },
        })),
      [Object.keys(cols), stringify(colsState)],
    )

  const visibleCols = useMemo(() => {
    const onlyVisibleCols = { ...cols }
    // remove hidden property (col names) from cols definition
    getOnlyHiddenColKeys(colsState).forEach((hiddenColName) => {
      if (hiddenColName in onlyVisibleCols) {
        delete onlyVisibleCols[hiddenColName]
      }
    })

    return onlyVisibleCols
  }, [stringify(colsState)])

  // check if something is changed against initial setup
  const isSomethingChanged = useMemo(() => {
    const currentHiddenCols = getOnlyHiddenColKeys(colsState)

    const res = Boolean(
      arraysSymetricDifference(currentHiddenCols, hiddenCols).length,
    )

    return res
  }, [stringify(hiddenCols), stringify(colsState)])

  return {
    visibleCols,
    colsWithCallbacks,
    isSomethingChanged,
  }
}
