import clsx from 'clsx'
import { BreakpointType } from '../../atoms/BreakpointDropdown/BreakpointDropdown'
import { HeaderDropdown } from '../../atoms/HeaderDropdown/HeaderDropdown'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { useFormContext } from '../../form/context/FormContext'
import {
  BackgroundType,
  RHFBackgroundSettingsPropType,
} from './BackgroundSettings'
import { BreakpointBackgroundFields } from './components/BreakpointBackgroundFields'
import { resolveActiveBackgrounds } from './helpers'

export const DefaultBackgroundSettings = ({
  basePath,
  className,
  title = 'Background',
  onMediaAddClick,
}: RHFBackgroundSettingsPropType) => {
  const { values, register, registerValues } = useFormContext({
    name: basePath,
  })
  const valuesPath = basePath || ''
  const hasExistingValues = Object.keys(values).some((val) =>
    val.includes('responsiveConfig'),
  )

  const onBackgroundTypeSelect = ({
    breakpoint,
    type,
  }: {
    breakpoint: BreakpointType | 'default'
    type: BackgroundType
  }) => {
    if (type === 'color' || type === 'overlay') {
      register({
        [`${valuesPath}.responsiveConfig.${breakpoint}.${type}`]:
          registerValues[`${valuesPath}.responsiveConfig.default.${type}`],
      })
    }
    if (type === 'image') {
      register({
        [`${valuesPath}.responsiveConfig.${breakpoint}.image.src`]:
          registerValues[`${valuesPath}.responsiveConfig.default.image.src`],
        [`${valuesPath}.responsiveConfig.${breakpoint}.image.alt`]:
          registerValues[`${valuesPath}.responsiveConfig.default.image.alt`],
        [`${valuesPath}.fit`]: registerValues[`${valuesPath}.fit`],
        [`${valuesPath}.parallax`]: registerValues[`${valuesPath}.parallax`],
      })
    }
  }

  return (
    <SegmentWrapper className={clsx('sc-rhf-spacing-settings', className)}>
      <HeaderSegment
        heading={title}
        customChildren={
          <HeaderDropdown
            options={['overlay', 'color', 'image']}
            disabledOptions={resolveActiveBackgrounds({
              values,
              valuesPath,
              breakpoint: 'default',
            })}
            onSelect={(type) => {
              onBackgroundTypeSelect({
                breakpoint: 'default',
                type: type as BackgroundType,
              })
            }}
          />
        }
      />
      {hasExistingValues && (
        <BreakpointBackgroundFields
          breakpoint={'default'}
          valuesPath={valuesPath}
          onMediaAddClick={onMediaAddClick}
        />
      )}
    </SegmentWrapper>
  )
}
