import { EditorState } from 'lexical'
import { useContext } from 'react'
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import FloatingTextFormatToolbarPlugin from './plugins/floatingTextFormatToolbar/FloatingTextFormatToolbarPlugin'
import { WysiwygContext } from './WysiwygProvider'
import { OnEditorStateChangePlugin } from './plugins/onEditorStateChange/OnEditorStateChangePlugin'
import { OnChangePlugin } from './plugins/onChange/OnChangePlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import wysiwygConfig from '@sceneio/config-wysiwyg'
import type { CommonEditorType } from './types'
import { debounce } from '@sceneio/tools/lib/debounce'
import { FixPlaceholderPlugin } from './plugins/fixPlaceholderPlugin/FixPlaceholderPlugin'
import FloatingLinkEditorPlugin from './plugins/floatingLinkEditorPlugin/FloatingLinkEditorPlugin'
import { AutoLinkPlugin } from './plugins/autoLinkPlugin/AutoLinkPlugin'

export type WysiwygTextEditor = CommonEditorType

export function WysiwygTextEditor({
  componentId,
  serialisedEditorState,
  floatingWhitelist = ['tag', 'history', 'list', 'format', 'link', 'ai'],
}: WysiwygTextEditor) {
  const { onSubmit, anchorRef, resolvePathFromId } = useContext(WysiwygContext)

  const initialConfig: InitialConfigType = {
    ...wysiwygConfig,
    namespace: 'TextEditor',
    editorState: serialisedEditorState,
    onError: (error: Error) => {
      console.log(error)
    },
  }

  function handleChange(editorState: EditorState) {
    const editorStateJSON = editorState.toJSON()
    const serializedEditorState = JSON.stringify(editorStateJSON)
    if (resolvePathFromId) {
      onSubmit({
        name: resolvePathFromId(componentId),
        data: serializedEditorState,
      })
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className="wysiwyg-container notranslate" data-no-drag>
        <FixPlaceholderPlugin>
          <RichTextPlugin
            contentEditable={
              <div className="wysiwyg">
                <ContentEditable className="wysiwyg-input" />
              </div>
            }
            placeholder={
              <div className="wysiwyg-placeholder">Start Typing...</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </FixPlaceholderPlugin>
        <HistoryPlugin />
        <ListPlugin />
        <LinkPlugin />
        {/*<AutoLinkPlugin />*/}
        <OnChangePlugin onChange={debounce(handleChange, 500)} />
        <OnEditorStateChangePlugin
          serialisedEditorState={serialisedEditorState || ''}
        />
        {anchorRef.current &&
          Boolean(floatingWhitelist) &&
          Boolean(floatingWhitelist?.length) && (
            <>
              <FloatingTextFormatToolbarPlugin
                anchorElem={anchorRef.current}
                floatingWhitelist={floatingWhitelist}
              />
              <FloatingLinkEditorPlugin anchorElem={anchorRef.current} />
            </>
          )}
      </div>
    </LexicalComposer>
  )
}
