import { useFormContext } from '../../form/context/FormContext'
import { ScrollToViewHelper } from '../../helpers/ScrollToViewHelper'
import { DefaultBackgroundSettings } from './DefaultBackgroundSettings'
import { ResponsiveBackgroundSettings } from './ResponsiveBackgroundSettings'

export type BackgroundType = 'overlay' | 'color' | 'image'

export type RHFBackgroundSettingsPropType = {
  title?: string
  basePath?: string
  className?: string
  onMediaAddClick?: (arg0: {
    fieldName: string
    sourceType: 'IMAGE' | 'VIDEO'
  }) => void
}

export const RHFBackgroundSettings = ({
  basePath,
  className,
  title = 'Background',
  onMediaAddClick,
}: RHFBackgroundSettingsPropType) => {
  const { forceBreakpoint, excludedSettings } = useFormContext({
    name: basePath,
  })

  if (excludedSettings.includes('background')) {
    return null
  }

  if (forceBreakpoint) {
    return (
      <ResponsiveBackgroundSettings
        basePath={basePath}
        className={className}
        title={title}
        onMediaAddClick={onMediaAddClick}
      />
    )
  }

  return (
    <>
      <ScrollToViewHelper type="background" />
      <DefaultBackgroundSettings
        basePath={basePath}
        className={className}
        title={title}
        onMediaAddClick={onMediaAddClick}
      />
    </>
  )
}
