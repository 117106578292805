import React from 'react'
import clsx from 'clsx'
import type { BannerBlock } from '@sceneio/schemas/lib/types'
import Block from '../../../components/Block/Block'
import Media from '../../../components/Media/Media'
import Text from '../../../components/Text/Text'
import Image from '../../../components/Image/Image'
import Button from '../../../components/Button/Button'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { Container } from '../../../components/Container/Container'
import { bannerDefaultConfig, buttonSampleFactory } from './bannerMeta'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'

function Banner({
  id,
  components: {
    block,
    logo,
    subheading,
    text,
    heading,
    background,
    author,
    buttons,
  },
  editor,
  preview,
}: BannerBlock = bannerDefaultConfig) {
  const { className, ...restBlockProps } = block || {}

  const bannerClass = clsx(`sc-banner sc-banner--default`, className)

  const showButtons = Boolean(buttons?.items?.length > 0 && buttons?.show)

  return (
    <Block className={bannerClass} id={id} editor={editor} {...restBlockProps}>
      {background && (
        <Media
          {...background}
          editor={{
            ...editor?.component,
            blockId: id,
            id: 'background',
          }}
          components={{
            ...background.components,
            caption: {
              show: false,
            },
            video: {
              ...('video' in background.components
                ? {
                    isBackgroundVideo: true,
                    ...background.components.video,
                  }
                : undefined),
            },
          }}
          cover
          className={clsx('sc-banner__background', background.className)}
          cid={resolveComponentId(id, 'background')}
          preview={preview}
        />
      )}
      <Image
        {...logo}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'logo',
        }}
      />
      <Text
        {...subheading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'subheading',
          wysiwyg: {
            type: 'HEADING',
          },
        }}
        cid={resolveComponentId(id, 'subheading')}
      />
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
          wysiwyg: {
            type: 'HEADING',
          },
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <Text
        {...author}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'author',
        }}
        cid={resolveComponentId(id, 'author')}
      />
      {/* <Social
        presentation="regular"
        {...social}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'social',
        }}
        className="sc-banner__social"
      /> */}
      <Container
        className={'sc-banner__buttons bui-flex-wrap'}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'buttons',
        }}
        {...buttons}
        name="componentArray"
        show={showButtons}
      >
        {buttons?.items?.map(
          (
            {
              id: buttonId,
              childrenWrapProps = {},
              className: buttonClassname,
              ...restButtonProps
            },
            index,
          ) => (
            <Button
              className={clsx('sc-banner__button', buttonClassname)}
              childrenWrapProps={{
                ...childrenWrapProps,
              }}
              key={buttonId || index}
              cid={resolveComponentId(id, `button-${index}`)}
              {...restButtonProps}
              editor={{
                ...editor?.component,
                blockId: id,
                id: `buttons.items[${index}]`,
                wysiwyg: {
                  type: 'ITEMS',
                  ...editorItemsControlsFactory({
                    itemsArr: buttons.items || [],
                    itemsId: `buttons.items`,
                    itemFactory: buttonSampleFactory,
                    index: index,
                  }),
                },
              }}
            />
          ),
        )}
      </Container>
    </Block>
  )
}

export const bannerPlugins: PluginsType = {
  js: ['video', 'animation', 'social'],
  css: [],
}
export default Banner
