import { stringify } from '@sceneio/tools/lib/stringify'

const NAMESPACE_SEPARATOR = '::'
let initialLoadingState = false
const mapStorage = new Map()

const persistMethods = {
  storeKeyValue: (key: string, value: unknown): void => {
    try {
      window.localStorage.setItem(key, stringify(value))
    } catch (err: any) {
      console.warn('[cacheFactory]', err.message)
    }
  },
  loadInitialState: (map: typeof mapStorage) => {
    Object.entries(localStorage).forEach(([key, value]) => {
      let targetValue

      try {
        targetValue = JSON.parse(value)
      } catch (error) {
        targetValue = value
      }

      map.set(key, targetValue)
    })
  },
} as const

export function cacheFactory(namespace: string) {
  if (!namespace) throw Error('Cache namespace not defined!')

  if (initialLoadingState === false) {
    persistMethods.loadInitialState(mapStorage)
    initialLoadingState = true
  }

  const namespacedKey = (key: string) =>
    `${String(namespace).trim()}${NAMESPACE_SEPARATOR}${key}`

  function has(key: string) {
    return mapStorage.has(namespacedKey(key))
  }

  function set(key: string, value: unknown) {
    const targetKey = namespacedKey(key)
    mapStorage.set(targetKey, value)
    persistMethods.storeKeyValue(targetKey, value)
    return value
  }

  function get(key: string, callbackFn?: () => unknown) {
    const targetKey = namespacedKey(key)

    if (mapStorage.has(targetKey)) {
      return mapStorage.get(targetKey)
    }

    if (callbackFn) {
      const result = callbackFn()
      set(targetKey, result)

      return result
    }

    return undefined
  }

  function keys() {
    const regular = new RegExp(`^${namespace}${NAMESPACE_SEPARATOR}`)
    return [...mapStorage.keys()].filter((item: string) =>
      regular.test(item),
    ) as string[]
  }

  function namespaces() {
    return Object.keys(
      keys().reduce(
        (result, item: string) => ({
          ...result,
          [item.split(NAMESPACE_SEPARATOR)[0]]: true,
        }),
        {},
      ),
    )
  }

  return {
    has,
    set,
    get,
    keys,
    namespaces,
  } as const
}
