import React, { useContext } from 'react'
import clsx from 'clsx'
import stringify from 'json-stringify-safe'
import ButtonBase from '../ButtonBase/ButtonBase'
import Text from '../Text/Text'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import hashString from '../../tools/crypt/hashString'
import ReactDOMServer from 'react-dom/server'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { buttonDefaults } from './buttonMeta'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getFontSmoothingCssVariables } from '../../tools/cssVariables/getFontSmoothingCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { ButtonComponent } from '@sceneio/schemas/lib/types'
import { AppContext } from '@sceneio/components-app-context'
import IconComponent from '../Icon/Icon'

const BUTTON_DEFAULTS = buttonDefaults.config

function Button({ children, ...props }: ButtonComponent) {
  const app = useContext(AppContext)
  const isEditor = app !== 'WEBSITE'
  const {
    components: { icon } = {},
    className: classNameProp,
    disabled = BUTTON_DEFAULTS.disabled,
    width = {},
    font = {},
    padding = {},
    margin = {},
    color = {},
    backgroundColor = {},
    boxShadow = {},
    textShadow = {},
    border = {},
    childrenWrapProps: {
      className: childrenWrapClassName,
      dataAttributes: childrenWrapDataAttributes,
    } = {},
    iconWrapProps: { className: iconWrapClassName, ...iconWrapRest } = {},
    as,
    tabIndex,
    type,
    link,
    ariaAttributes = {},
    dataAttributes = {},
    onClick,
    'data-bs-toggle': dataToggle,
    id,
    cid,
    isAnimated = BUTTON_DEFAULTS.isAnimated,
    enableEditor,
    show = true,
    editor,
    style,
  } = props

  if (!show) {
    return null
  }

  const className = clsx(
    'sc-button',
    {
      'sc-button--disabled': disabled,
    },
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    classNameProp,
  )

  const iconBeforeClassName = clsx(
    'sc-button__icon',
    'sc-button__left-icon',
    iconWrapClassName,
  )
  const iconAfterClassName = clsx(
    'sc-button__icon',
    'sc-button__right-icon',
    iconWrapClassName,
  )
  // for analytics trackings
  const dataButtonId = {
    'data-button-id': hashString(
      stringify({
        ...props,
        children: ReactDOMServer.renderToString(children as any),
      }),
    ),
  }

  return (
    <>
      <ButtonBase
        data-component-id={editor?.id}
        className={className}
        disabled={disabled}
        as={as}
        tabIndex={tabIndex}
        type={type}
        link={isEditor ? undefined : link}
        dataAttributes={{
          'data-plugin-animation': isAnimated ? true : null,
          ...dataAttributes,
          ...dataButtonId,
        }}
        ariaAttributes={{
          ...ariaAttributes,
        }}
        onMouseDown={(e) => {
          if (onClick) {
            onClick(e)
          }
        }}
        data-bs-toggle={dataToggle}
        id={id}
        style={{
          ...style,
          ...getFontSmoothingCssVariables({ font, prefix: 'button' }),
          ...getRootLevelCssVariables({
            cssVariables: {
              width,
              padding,
              margin,
              border,
              color,
              backgroundColor,
              font,
            },
            prefix: 'button',
          }),
          ...getShadowCssVariables({
            shadow: boxShadow,
            shadowType: 'box',
            prefix: 'button',
          }),
          ...getShadowCssVariables({
            shadow: textShadow,
            shadowType: 'text',
            prefix: 'button',
          }),
        }}
      >
        {(icon?.mode === 'leading' || icon?.mode === 'only') && (
          <IconComponent {...icon} className={iconBeforeClassName} />
        )}
        <Text
          as="span"
          className={'sc-button__label'}
          cid={resolveComponentId(cid, 'label')}
          enableEditor={enableEditor}
          dataAttributes={childrenWrapDataAttributes}
          isAnimated={isAnimated}
          editor={editor}
          show={icon?.mode !== 'only'}
        >
          {children}
        </Text>
        {icon?.mode === 'trailing' && (
          <IconComponent {...icon} className={iconAfterClassName} />
        )}
      </ButtonBase>
    </>
  )
}

export const plugins: PluginsType = {
  js: ['animation'],
  css: [],
}

export default Button
