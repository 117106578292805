import { DataTable } from '@sceneio/components-data-table'

const testData = [
  {
    value: '/',
    id: 'cksbj4r741270b0vedhifeini',
    level: 0,
    redirect: { toPathValue: '/the-easy-life', toUrl: null },
    createdAt: '2021-08-14T08:40:49.840Z',
    content: {
      createdAt: '2020-08-25T14:54:33.795Z',
      id: 'ckea2ltmr0000sc25stxpcg8r',
      publishState: 'PUBLISHED',
      name: 'Southern Comfort DE',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: 'scene-language-id-en',
    themeId: 'ckewixnrl004818q8hyzuclp0',
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/about-us',
    id: 'cksbj4r751274b0veq7oajhp7',
    level: 1,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2020-09-10T12:35:51.484Z',
      id: 'ckewsp2rg007018q83tgg60us',
      publishState: 'PUBLISHED',
      name: 'About us',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers',
    id: 'cksbj4r751275b0vef6culwxz',
    level: 1,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2020-09-29T11:35:50.181Z',
      id: 'ckfnvx2n9012718qt966wpl6q',
      publishState: 'PUBLISHED',
      name: 'Culture Makers',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/5-bucher-fur-den-herbst-und-winter',
    id: 'ckvqxaqlj4867861lr4jyzq67wa',
    level: 2,
    redirect: null,
    createdAt: '2021-11-08T17:13:03.271Z',
    content: {
      createdAt: '2021-11-08T17:13:02.810Z',
      id: 'ckvqxaq8q4867401lr49ohmr461',
      publishState: 'PUBLISHED',
      name: '5 Bücher für den Herbst und Winter',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/7-spannende-podcasts-die-euch-durch-den-winter-bringen',
    id: 'cksbj4r751279b0ver74kcw5z',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2020-12-01T15:01:37.315Z',
      id: 'cki640dr706011gmq61rco0bv',
      publishState: 'PUBLISHED',
      name: '7 SPANNENDE PODCASTS, DIE EUCH DURCH DEN WINTER BRINGEN',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/8-filmtipps-fur-die-ruhigen-tages-des-jahres',
    id: 'cksbj4r751281b0vemhlc7ysy',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2020-12-18T21:20:21.495Z',
      id: 'ckius0x6f014218nw71toyk01',
      publishState: 'PUBLISHED',
      name: '8 Filmtipps für die „ruhigen“ Tages des Jahres',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/ausgezeichnet-southern-comfort-black-makeiteasy',
    id: 'cksbj4r761291b0vee86zqegr',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2021-06-10T17:53:43.591Z',
      id: 'ckpr79ew62150301olhfhlkrlw1',
      publishState: 'PUBLISHED',
      name: 'Ausgezeichnet: Southern Comfort Black #MakeItEasy',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/coffee-to-stay-julian-lechner-macht-tassen-aus-kaffeesatz',
    id: 'cksbj4r751276b0ved6b47k5l',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2020-09-29T10:58:37.298Z',
      id: 'ckfnul7qq011118qtlx3btsv2',
      publishState: 'PUBLISHED',
      name: 'Coffee to stay: Julian Lechner macht Tassen aus Kaffeesatz',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/diese-7-bucher-solltet-ihr-unbedingt-lesen',
    id: 'cksbj4r751284b0ve2mcyf3gz',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2021-01-29T15:14:11.536Z',
      id: 'ckkifft1s018017l9j3j83g1f',
      publishState: 'PUBLISHED',
      name: 'Diese 7 Bücher solltet ihr unbedingt lesen',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/dieser-mann-bringt-die-rille-in-die-schallplatte-stefan-betke-schneidet-vinyl',
    id: 'cksbj4r761286b0ve8e9sfarl',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2021-03-01T13:09:28.985Z',
      id: 'cklqlmu55088618mp9ieme7gp',
      publishState: 'PUBLISHED',
      name: 'Dieser Mann bringt die Rille in die Schallplatte – Stefan Betke schneidet Vinyl ',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/drew-mayville-wenn-die-perfekte-balance-einmal-gefunden-ist-erkennt-man-das-sofort',
    id: 'cksbj4r761289b0vejur0r4in',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2021-05-05T13:16:28.379Z',
      id: 'ckobhi72z12838531mri8ajdxckb',
      publishState: 'PUBLISHED',
      name: 'Drew Mayville: „Wenn die perfekte Balance einmal gefunden ist, erkennt man das sofort“',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/eine-kleine-eisgeschichte',
    id: 'cksbj4r751282b0ve3honieds',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2021-01-29T16:18:15.561Z',
      id: 'ckkihq749028618pakyfyrhza',
      publishState: 'PUBLISHED',
      name: 'Eine kleine Eisgeschichte',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/eine-kleine-whisky-geschichte',
    id: 'cksbj4r751278b0vegu0rk9o4',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2020-10-13T14:09:05.283Z',
      id: 'ckg81k2yr00131go5ro3vh1xs',
      publishState: 'PUBLISHED',
      name: 'Eine kleine Whisky-Geschichte',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/ein-paar-beste-songs-fur-halloween-instant-gruselei-fur-den-schocker-abend',
    id: 'ckuzlywwb5509761lml1ysos0nr',
    level: 2,
    redirect: null,
    createdAt: '2021-10-20T14:26:09.035Z',
    content: {
      createdAt: '2021-10-20T14:26:08.584Z',
      id: 'ckuzlywjs5509301lmljwwu1rlg',
      publishState: 'PUBLISHED',
      name: 'Ein paar beste Songs für Halloween. Instant-Gruselei für den Schocker-Abend',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/mehr-spass-im-freien-5-sport-und-spiele-ideen-fur-park-und-co',
    id: 'ckt4rrhq06418521mnn777morpa',
    level: 2,
    redirect: null,
    createdAt: '2021-09-03T19:47:46.680Z',
    content: {
      createdAt: '2021-09-03T19:47:46.150Z',
      id: 'ckt4rrhba6417891mnn8mbtt5l8',
      publishState: 'PUBLISHED',
      name: 'Mehr Spaß im Sommer 2021: 5 Sport- und Spiele-Ideen für draußen',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/on-the-rocks-musik-die-bewegt-heute-apifera-overstand',
    id: 'cksbj4r751285b0ve274k8qzf',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2021-02-25T02:04:38.600Z',
      id: 'cklk84fuw024018s1tknemexu',
      publishState: 'PUBLISHED',
      name: 'On the Rocks – Musik, die bewegt. Heute Apifera – Overstand',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/on-the-rocks-musik-die-bewegt-heute-christine-ott',
    id: 'cksbj4r761290b0ve3xxv3n39',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2021-05-05T14:23:59.368Z',
      id: 'ckobjx0uf13199391nlq2lvf33sa',
      publishState: 'PUBLISHED',
      name: 'On The Rocks –  Musik die bewegt: Heute: Christine Ott',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/on-the-rocks-musik-die-bewegt-heute-clairo',
    id: 'cksbj4r761292b0veftoo5u1s',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2021-08-11T19:31:48.611Z',
      id: 'cks7w2d4z15832181mlqa2jv1s20',
      publishState: 'PUBLISHED',
      name: 'On The Rocks – Musik die bewegt: Heute: Clairo',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/on-the-rocks-musik-die-bewegt-heute-floating-points-pharoah-sanders-and-the-london-symphony-orchestrapromises',
    id: 'cksbj4r761287b0veiozet8y0',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2021-03-30T17:03:28.160Z',
      id: 'ckmw9rg6812427151nmgim35bqtr',
      publishState: 'PUBLISHED',
      name: 'On the rocks- Musik, die bewegt. Heute: Floating Points; Pharoah Sanders & The London Symphony Orchestra–PROMISES',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/on-the-rocks-musik-die-bewegt-heute-martyn-heyne-open-lines',
    id: 'cksbj4r751283b0ve1flpqsg7',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2020-11-25T15:13:26.979Z',
      id: 'ckhxjshc305421gmqhn14ujhu',
      publishState: 'PUBLISHED',
      name: 'On the Rocks – Musik, die bewegt. Heute: Martyn Heyne - Open Lines',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/on-the-rocks-musik-die-bewegt-heute-nala-sinephro',
    id: 'ckucpid1w587051lqpt4e3jpd6',
    level: 2,
    redirect: null,
    createdAt: '2021-10-04T13:46:33.236Z',
    content: {
      createdAt: '2021-10-04T13:46:32.792Z',
      id: 'ckucpicpk586591lqpqxi5757x',
      publishState: 'PUBLISHED',
      name: 'ON THE ROCKS – MUSIK DIE BEWEGT. HEUTE: NALA SINEPHRO',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/on-the-rocks-musik-die-bewegt-heute-skalpel-highlight',
    id: 'cksbj4r751277b0ve9361e6f6',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2020-09-29T10:58:57.474Z',
      id: 'ckfnulnb6011718qt53jgkrb5',
      publishState: 'PUBLISHED',
      name: 'On the rocks – Musik, die bewegt. Heute: Skalpel – Highlight',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/video-portrait-urban-terrazzo',
    id: 'cksbj4r751280b0ve93s14fou',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.841Z',
    content: {
      createdAt: '2020-11-25T14:58:35.766Z',
      id: 'ckhxj9do603331gmfxxddolx5',
      publishState: 'PUBLISHED',
      name: 'Video-Portrait: Urban Terrazzo',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/culture-makers/vorhang-auf-fur-southern-comfort-black',
    id: 'cksbj4r761288b0vevbu7nsq3',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2021-04-15T17:03:31.931Z',
      id: 'cknj4t5qz12180641mo19edw66ik',
      publishState: 'PUBLISHED',
      name: 'Vorhang auf für Southern Comfort Black!',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/culture-makers/yes-you-can-southern-comfort-gibts-jetzt-trinkfertig-gemixt',
    id: 'cktvqj1t0134791lpinxb5osqc',
    level: 2,
    redirect: null,
    createdAt: '2021-09-22T16:42:59.940Z',
    content: {
      createdAt: '2021-09-22T16:42:59.496Z',
      id: 'cktvqj1go134331lpih6puhn6p',
      publishState: 'PUBLISHED',
      name: 'Yes You Can: Southern Comfort gibt’s jetzt trinkfertig gemixt',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/funfacts',
    id: 'cksbj4r741273b0veeiwidr78',
    level: 1,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.840Z',
    content: {
      createdAt: '2020-09-29T11:41:12.866Z',
      id: 'ckfnw3zmq013418qtb1f4ebma',
      publishState: 'DRAFT',
      name: 'Funfacts',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/places-and-spaces',
    id: 'cksbj4r781311b0velrbssv4i',
    level: 1,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.844Z',
    content: {
      createdAt: '2020-09-29T11:39:12.230Z',
      id: 'ckfnw1ejq013118qtchev8eyc',
      publishState: 'PUBLISHED',
      name: 'Places & Spaces',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/places-and-spaces/5-brett-und-kartenspiele-fur-den-anstehenden-fruhling',
    id: 'cksbj4r781314b0vemtia94gk',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.844Z',
    content: {
      createdAt: '2021-03-15T10:02:00.589Z',
      id: 'ckmaf3o5q001118o1oio6kgdb',
      publishState: 'PUBLISHED',
      name: '5 Brett- und Kartenspiele für den anstehenden Frühling',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/places-and-spaces/5-mikroabenteuer-mit-denen-du-deine-lockdown-langeweile-vertreibst',
    id: 'cksbj4r781316b0velpnibzhu',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.844Z',
    content: {
      createdAt: '2021-05-25T18:44:38.819Z',
      id: 'ckp4e19nm856191nlqug0mwx0a',
      publishState: 'PUBLISHED',
      name: '5 Mikroabenteuer, mit denen du deine Lockdown-Langeweile vertreibst',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/places-and-spaces/5-tipps-fur-die-perfekte-halloween-party',
    id: 'ckuzkhrh13214061mlp0mgfphe4',
    level: 2,
    redirect: null,
    createdAt: '2021-10-20T13:44:49.237Z',
    content: {
      createdAt: '2021-10-20T13:44:48.804Z',
      id: 'ckuzkhr503213601mlpz08yaexs',
      publishState: 'PUBLISHED',
      name: '5 Tipps für die perfekte Halloween-Party',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/places-and-spaces/der-perfekte-herbst-partydrink-die-southern-comfort-bowle',
    id: 'ckufnwu1k400391mmiualtem4j',
    level: 2,
    redirect: null,
    createdAt: '2021-10-06T15:25:07.736Z',
    content: {
      createdAt: '2021-10-06T15:25:07.295Z',
      id: 'ckufnwtpb399931mmivsf1324w',
      publishState: 'PUBLISHED',
      name: 'Der perfekte Herbst-Partydrink: Die Southern Comfort Bowle!',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/places-and-spaces/digitales-dinner-for-fun-so-wird-das-abendessen-am-rechner-zur-party',
    id: 'cksbj4r781315b0vewad6zrsm',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.844Z',
    content: {
      createdAt: '2021-03-17T23:14:26.390Z',
      id: 'ckme2ag2e01261ame5ajchybe',
      publishState: 'PUBLISHED',
      name: 'Digitales Dinner for Fun: So wird das Abendessen am Rechner zur Party!',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/places-and-spaces/streuobstwiesen-die-geheimen-reservate-mitteleuropas',
    id: 'cksbj4r781313b0vevxbko00p',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.844Z',
    content: {
      createdAt: '2020-11-02T20:44:53.148Z',
      id: 'ckh10i49o04011iop7j0tctyt',
      publishState: 'PUBLISHED',
      name: 'Streuobstwiesen: die geheimen Reservate Mitteleuropas',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: 'ckhkxkiby00001gmf3a1o7qy9',
    inheritedTheme: {
      id: 'ckhkxkiby00001gmf3a1o7qy9',
      title: 'Default flipbook',
    },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/places-and-spaces/vinyl-die-renaissance-der-schallplatte',
    id: 'cksbj4r781312b0veaxyyto3a',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.844Z',
    content: {
      createdAt: '2020-09-29T10:58:25.839Z',
      id: 'ckfnukywf010818qt3wjx2j6w',
      publishState: 'PUBLISHED',
      name: 'Vinyl – die Renaissance der Schallplatte',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society',
    id: 'cksbj4r761293b0velqq4zkge',
    level: 1,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2020-09-29T11:38:04.594Z',
      id: 'ckfnvzycy013018qt2j0234mw',
      publishState: 'PUBLISHED',
      name: 'Society',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/10-dinge-fur-einen-unvergesslichen-valentinstag-2021',
    id: 'cksbj4r771298b0vea6210i9h',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2021-02-11T13:42:53.613Z',
      id: 'ckl0wwgx9267518mloqufk73u',
      publishState: 'PUBLISHED',
      name: '10 Dinge für einen unvergesslichen Valentinstag 2021',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/5-kochbucher-fur-mehr-spass-und-erfolg-in-der-kuche',
    id: 'cksbj4r771304b0ve83y4qfuz',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2021-04-21T15:59:23.035Z',
      id: 'cknrn5rx67649111nprh482z95h',
      publishState: 'PUBLISHED',
      name: '5 Kochbücher für mehr Spaß und Erfolg in der Küche',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/7-serien-tipps-fur-den-couch-marathon',
    id: 'cksbj4r761295b0ve5v9ijrpp',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2021-03-05T10:32:49.464Z',
      id: 'cklw5ss3c119718s1b1zgn225',
      publishState: 'PUBLISHED',
      name: '7 Serien-Tipps für den Couch-Marathon',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/society/7-weihnachtslieder-die-ihr-vermutlich-noch-nie-gehort-habt',
    id: 'ckwdqi5407084831lllpe5fyi0w',
    level: 2,
    redirect: null,
    createdAt: '2021-11-24T16:21:33.408Z',
    content: {
      createdAt: '2021-11-24T16:21:32.973Z',
      id: 'ckwdqi4rx7084371llltexgmdnq',
      publishState: 'PUBLISHED',
      name: ' 7 Weihnachtslieder, die ihr vermutlich noch nie gehört habt',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/9-schritte-zum-perfekten-hausgemachten-ginger-ale',
    id: 'cksbj4r761294b0veuapz4xba',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2020-09-29T10:58:48.630Z',
      id: 'ckfnulghi011418qt6cb829z3',
      publishState: 'PUBLISHED',
      name: '9 Schritte zum perfekten hausgemachten Ginger Ale',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/society/come-on-kombucha-unser-drinkrezept-mit-ingwer-and-southern-comfort',
    id: 'cksbj4r771301b0ve9a3mbh2u',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2021-02-18T15:50:58.851Z',
      id: 'cklb1k5k306101jr4e4gy64uf',
      publishState: 'PUBLISHED',
      name: 'Come on, Kombucha! Unser Drinkrezept mit Ingwer & Southern Comfort',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: 'ckhkxkiby00001gmf3a1o7qy9',
    inheritedTheme: {
      id: 'ckhkxkiby00001gmf3a1o7qy9',
      title: 'Default flipbook',
    },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/der-sommerhit-southern-comfort-milkshake',
    id: 'cksbj4r781308b0vey5m2cygr',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.844Z',
    content: {
      createdAt: '2021-07-23T14:09:39.695Z',
      id: 'ckrgf6w6n4104711mqw2fp8q329',
      publishState: 'PUBLISHED',
      name: 'Der Sommerhit: Southern Comfort Milkshake!',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: 'ckhkxkiby00001gmf3a1o7qy9',
    inheritedTheme: {
      id: 'ckhkxkiby00001gmf3a1o7qy9',
      title: 'Default flipbook',
    },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/die-besten-coop-games-fur-diesen-sommer',
    id: 'cksbj4r781307b0vejzeef0pu',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.844Z',
    content: {
      createdAt: '2021-06-27T12:52:20.272Z',
      id: 'ckqf6zb1s5421771mqttrsrbteg',
      publishState: 'PUBLISHED',
      name: 'Die besten Coop-Games für diesen Sommer',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/die-heisse-verfuhrung-southern-hot-chocolate',
    id: 'cksbj4r771296b0ves78aaeb2',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.842Z',
    content: {
      createdAt: '2021-01-06T11:43:17.744Z',
      id: 'ckjlcrzzk031118pmjhmt6zjs',
      publishState: 'PUBLISHED',
      name: 'Die heiße Verführung: Southern Hot Chocolate! ',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/die-story-von-southern-comfort',
    id: 'cksbj4r771297b0veoe19xgvy',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2021-02-04T13:07:59.032Z',
      id: 'ckkqvkm2g078317p4aorajh1v',
      publishState: 'PUBLISHED',
      name: 'Die Story von Southern Comfort',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/society/furs-herzhafte-food-vergnugen-southern-comfort-barbecue-sauce',
    id: 'cksbj4r771305b0vewc2ga8ro',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2021-05-25T20:15:20.340Z',
      id: 'ckp4h9wd02095581mpghld8hrlk',
      publishState: 'PUBLISHED',
      name: 'Fürs herzhafte Food-Vergnügen: Southern Comfort Barbecue-Sauce!',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/heiss-und-lecker-der-hot-southern-toddy',
    id: 'cksbj4r771299b0veyzmc254z',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2020-12-18T03:08:54.771Z',
      id: 'ckitp1bar001018s1vay99cug',
      publishState: 'PUBLISHED',
      name: 'Heiß und lecker: der Hot Southern Toddy! ',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/society/in-7-schritten-zu-deinem-eigenen-southern-comfort-black-milk-punch',
    id: 'cksbj4r771306b0vev4r4yq40',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2021-06-02T18:58:49.275Z',
      id: 'ckpfu2b7e5510951np5oc6aratj',
      publishState: 'PUBLISHED',
      name: 'In 7 Schritten zu deinem eigenen Southern Comfort Black Milk Punch',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/kaltes-klares-eis-der-coole-hingucker-fur-eure-drinks',
    id: 'cksvvrxm011960731nrv0j4p5l91',
    level: 2,
    redirect: null,
    createdAt: '2021-08-28T14:30:10.152Z',
    content: {
      createdAt: '2021-08-28T14:30:09.725Z',
      id: 'cksvvrxa511960271nrv2guth9rm',
      publishState: 'PUBLISHED',
      name: 'Kaltes klares Eis: Der coole Hingucker für eure Drinks!',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/society/langsame-musik-grosse-gefuhle-diese-10-songs-helfen-dir-durch-den-winter',
    id: 'cksbj4r771300b0ve64vz7u5j',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2020-12-18T22:18:14.528Z',
      id: 'ckiuu3czk032118s1reoxtdze',
      publishState: 'PUBLISHED',
      name: 'Langsame Musik – große Gefühle Diese 10 Songs helfen dir durch den Winter',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/southern-comfort-jelly-shots',
    id: 'cksbj4r771302b0vewi5cp8e2',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2021-04-05T19:47:28.916Z',
      id: 'ckn509hck3027531nmgb33qbr68',
      publishState: 'PUBLISHED',
      name: 'Southern Comfort Jelly shots! ',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/warum-machst-du-das',
    id: 'cksbj4r771303b0vexu40wabb',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.843Z',
    content: {
      createdAt: '2021-04-15T13:46:41.435Z',
      id: 'cknixs0pn10530171mo1ws0qaua5',
      publishState: 'PUBLISHED',
      name: 'Warum machst du das?',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/society/yummy-christmas-mit-dem-southern-comfort-eggnogg',
    id: 'ckwdin8k011577541lll5db9gm6z',
    level: 2,
    redirect: null,
    createdAt: '2021-11-24T12:41:34.224Z',
    content: {
      createdAt: '2021-11-24T12:41:33.785Z',
      id: 'ckwdin87t11577081llla5kex8cu',
      publishState: 'PUBLISHED',
      name: 'Yummy Christmas mit dem Southern Comfort Eggnogg! ',
      preferences: null,
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life',
    id: 'cksbj4r791317b0vexmf0ez5i',
    level: 1,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-09-29T11:02:11.853Z',
      id: 'ckfnuptal012118qtc1hmdut6',
      publishState: 'PUBLISHED',
      name: 'The Easy Life',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/10-dinge-fur-einen-unvergesslichen-valentinstag-2021',
    id: 'cksbj4r7a1336b0vechuvw25s',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.846Z',
    content: {
      createdAt: '2021-02-11T13:42:53.613Z',
      id: 'ckl0wwgx9267518mloqufk73u',
      publishState: 'PUBLISHED',
      name: '10 Dinge für einen unvergesslichen Valentinstag 2021',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/5-brett-und-kartenspiele-fur-den-anstehenden-fruhling',
    id: 'cksbj4r7b1337b0vez3pm2you',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-03-15T10:02:00.589Z',
      id: 'ckmaf3o5q001118o1oio6kgdb',
      publishState: 'PUBLISHED',
      name: '5 Brett- und Kartenspiele für den anstehenden Frühling',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/5-kochbucher-fur-mehr-spass-und-erfolg-in-der-kuche',
    id: 'cksbj4r7b1345b0vejqentdx3',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-04-21T15:59:23.035Z',
      id: 'cknrn5rx67649111nprh482z95h',
      publishState: 'PUBLISHED',
      name: '5 Kochbücher für mehr Spaß und Erfolg in der Küche',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/5-mikroabenteuer-mit-denen-du-deine-lockdown-langeweile-vertreibst',
    id: 'cksbj4r7b1348b0veoelgryh5',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-05-25T18:44:38.819Z',
      id: 'ckp4e19nm856191nlqug0mwx0a',
      publishState: 'PUBLISHED',
      name: '5 Mikroabenteuer, mit denen du deine Lockdown-Langeweile vertreibst',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/7-serien-tipps-fur-den-couch-marathon',
    id: 'cksbj4r791318b0ve0ejiajln',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2021-03-05T10:32:49.464Z',
      id: 'cklw5ss3c119718s1b1zgn225',
      publishState: 'PUBLISHED',
      name: '7 Serien-Tipps für den Couch-Marathon',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/7-spannende-podcasts-die-euch-durch-den-winter-bringen',
    id: 'cksbj4r791325b0ve6zr49wk1',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-12-01T15:01:37.315Z',
      id: 'cki640dr706011gmq61rco0bv',
      publishState: 'PUBLISHED',
      name: '7 SPANNENDE PODCASTS, DIE EUCH DURCH DEN WINTER BRINGEN',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/8-filmtipps-fur-die-ruhigen-tages-des-jahres',
    id: 'cksbj4r7a1328b0ve336eglgn',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-12-18T21:20:21.495Z',
      id: 'ckius0x6f014218nw71toyk01',
      publishState: 'PUBLISHED',
      name: '8 Filmtipps für die „ruhigen“ Tages des Jahres',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/9-schritte-zum-perfekten-hausgemachten-ginger-ale',
    id: 'cksbj4r791320b0veak6xlfi4',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-09-29T10:58:48.630Z',
      id: 'ckfnulghi011418qt6cb829z3',
      publishState: 'PUBLISHED',
      name: '9 Schritte zum perfekten hausgemachten Ginger Ale',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/ausgezeichnet-southern-comfort-black-makeiteasy',
    id: 'cksbj4r7c1351b0vekp45xjtu',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.848Z',
    content: {
      createdAt: '2021-06-10T17:53:43.591Z',
      id: 'ckpr79ew62150301olhfhlkrlw1',
      publishState: 'PUBLISHED',
      name: 'Ausgezeichnet: Southern Comfort Black #MakeItEasy',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/coffee-to-stay-julian-lechner-macht-tassen-aus-kaffeesatz',
    id: 'cksbj4r791319b0veynhc63ws',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-09-29T10:58:37.298Z',
      id: 'ckfnul7qq011118qtlx3btsv2',
      publishState: 'PUBLISHED',
      name: 'Coffee to stay: Julian Lechner macht Tassen aus Kaffeesatz',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/come-on-kombucha-unser-drinkrezept-mit-ingwer-and-southern-comfort',
    id: 'cksbj4r7b1338b0veg0jsjix5',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-02-18T15:50:58.851Z',
      id: 'cklb1k5k306101jr4e4gy64uf',
      publishState: 'PUBLISHED',
      name: 'Come on, Kombucha! Unser Drinkrezept mit Ingwer & Southern Comfort',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: 'ckhkxkiby00001gmf3a1o7qy9',
    inheritedTheme: {
      id: 'ckhkxkiby00001gmf3a1o7qy9',
      title: 'Default flipbook',
    },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/der-sommerhit-southern-comfort-milkshake',
    id: 'cksbj4r7c1353b0vedvfyyy6i',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.848Z',
    content: {
      createdAt: '2021-07-23T14:09:39.695Z',
      id: 'ckrgf6w6n4104711mqw2fp8q329',
      publishState: 'PUBLISHED',
      name: 'Der Sommerhit: Southern Comfort Milkshake!',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: 'ckhkxkiby00001gmf3a1o7qy9',
    inheritedTheme: {
      id: 'ckhkxkiby00001gmf3a1o7qy9',
      title: 'Default flipbook',
    },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/die-besten-coop-games-fur-diesen-sommer',
    id: 'cksbj4r7c1352b0veleqcopz9',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.848Z',
    content: {
      createdAt: '2021-06-27T12:52:20.272Z',
      id: 'ckqf6zb1s5421771mqttrsrbteg',
      publishState: 'PUBLISHED',
      name: 'Die besten Coop-Games für diesen Sommer',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/die-heisse-verfuhrung-southern-hot-chocolate',
    id: 'cksbj4r7a1334b0ve7sopp1hf',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.846Z',
    content: {
      createdAt: '2021-01-06T11:43:17.744Z',
      id: 'ckjlcrzzk031118pmjhmt6zjs',
      publishState: 'PUBLISHED',
      name: 'Die heiße Verführung: Southern Hot Chocolate! ',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/diese-7-bucher-solltet-ihr-unbedingt-lesen',
    id: 'cksbj4r7a1330b0ve11xl8o75',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.846Z',
    content: {
      createdAt: '2021-01-29T15:14:11.536Z',
      id: 'ckkifft1s018017l9j3j83g1f',
      publishState: 'PUBLISHED',
      name: 'Diese 7 Bücher solltet ihr unbedingt lesen',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/dieser-mann-bringt-die-rille-in-die-schallplatte-stefan-betke-schneidet-vinyl',
    id: 'cksbj4r7b1340b0ve1ll7i8jc',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-03-01T13:09:28.985Z',
      id: 'cklqlmu55088618mp9ieme7gp',
      publishState: 'PUBLISHED',
      name: 'Dieser Mann bringt die Rille in die Schallplatte – Stefan Betke schneidet Vinyl ',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/die-story-von-southern-comfort',
    id: 'cksbj4r7a1335b0veysl7gdbz',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.846Z',
    content: {
      createdAt: '2021-02-04T13:07:59.032Z',
      id: 'ckkqvkm2g078317p4aorajh1v',
      publishState: 'PUBLISHED',
      name: 'Die Story von Southern Comfort',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/digitales-dinner-for-fun-so-wird-das-abendessen-am-rechner-zur-party',
    id: 'cksbj4r7a1329b0vemv0eue7l',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.846Z',
    content: {
      createdAt: '2021-03-17T23:14:26.390Z',
      id: 'ckme2ag2e01261ame5ajchybe',
      publishState: 'PUBLISHED',
      name: 'Digitales Dinner for Fun: So wird das Abendessen am Rechner zur Party!',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/drew-mayville-wenn-die-perfekte-balance-einmal-gefunden-ist-erkennt-man-das-sofort',
    id: 'cksbj4r7b1346b0verge41m41',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-05-05T13:16:28.379Z',
      id: 'ckobhi72z12838531mri8ajdxckb',
      publishState: 'PUBLISHED',
      name: 'Drew Mayville: „Wenn die perfekte Balance einmal gefunden ist, erkennt man das sofort“',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/eine-kleine-eisgeschichte',
    id: 'cksbj4r7a1331b0ve7f900mq6',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.846Z',
    content: {
      createdAt: '2021-01-29T16:18:15.561Z',
      id: 'ckkihq749028618pakyfyrhza',
      publishState: 'PUBLISHED',
      name: 'Eine kleine Eisgeschichte',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/eine-kleine-whisky-geschichte',
    id: 'cksbj4r791322b0vek20slywv',
    level: 2,
    redirect: {
      toPathValue: '/the-easy-life/vinyl-die-renaissance-der-schallplatte',
      toUrl: null,
    },
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-10-13T14:09:05.283Z',
      id: 'ckg81k2yr00131go5ro3vh1xs',
      publishState: 'PUBLISHED',
      name: 'Eine kleine Whisky-Geschichte',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/furs-herzhafte-food-vergnugen-southern-comfort-barbecue-sauce',
    id: 'cksbj4r7c1349b0vebp43z44j',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.848Z',
    content: {
      createdAt: '2021-05-25T20:15:20.340Z',
      id: 'ckp4h9wd02095581mpghld8hrlk',
      publishState: 'PUBLISHED',
      name: 'Fürs herzhafte Food-Vergnügen: Southern Comfort Barbecue-Sauce!',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/heiss-und-lecker-der-hot-southern-toddy',
    id: 'cksbj4r7a1333b0veh9md6cow',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.846Z',
    content: {
      createdAt: '2020-12-18T03:08:54.771Z',
      id: 'ckitp1bar001018s1vay99cug',
      publishState: 'PUBLISHED',
      name: 'Heiß und lecker: der Hot Southern Toddy! ',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/in-7-schritten-zu-deinem-eigenen-southern-comfort-black-milk-punch',
    id: 'cksbj4r7c1350b0ve8ffgljuk',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.848Z',
    content: {
      createdAt: '2021-06-02T18:58:49.275Z',
      id: 'ckpfu2b7e5510951np5oc6aratj',
      publishState: 'PUBLISHED',
      name: 'In 7 Schritten zu deinem eigenen Southern Comfort Black Milk Punch',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/kaltes-klares-eis-der-coole-hingucker-fur-eure-drinks',
    id: 'cksbj4r7c1354b0vek6x8ouvg',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.848Z',
    content: {
      createdAt: '2021-07-26T15:09:02.111Z',
      id: 'ckrkrmsym6874831mlqpd1hk9yu',
      publishState: 'PUBLISHED',
      name: 'Kaltes klares Eis: Der coole Hingucker für eure Drinks!',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/langsame-musik-grosse-gefuhle-diese-10-songs-helfen-dir-durch-den-winter',
    id: 'cksbj4r7a1332b0vejtgk8aow',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.846Z',
    content: {
      createdAt: '2020-12-18T22:18:14.528Z',
      id: 'ckiuu3czk032118s1reoxtdze',
      publishState: 'PUBLISHED',
      name: 'Langsame Musik – große Gefühle Diese 10 Songs helfen dir durch den Winter',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/on-the-rocks-musik-die-bewegt-heute-apifera-overstand',
    id: 'cksbj4r7b1339b0veeuet1fuy',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-02-25T02:04:38.600Z',
      id: 'cklk84fuw024018s1tknemexu',
      publishState: 'PUBLISHED',
      name: 'On the Rocks – Musik, die bewegt. Heute Apifera – Overstand',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/on-the-rocks-musik-die-bewegt-heute-christine-ott',
    id: 'cksbj4r7b1347b0vea0rvsfuh',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-05-05T14:23:59.368Z',
      id: 'ckobjx0uf13199391nlq2lvf33sa',
      publishState: 'PUBLISHED',
      name: 'On The Rocks –  Musik die bewegt: Heute: Christine Ott',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/on-the-rocks-musik-die-bewegt-heute-clairo',
    id: 'cksbj4r7c1355b0veuy6j6hf2',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.848Z',
    content: {
      createdAt: '2021-08-11T19:31:48.611Z',
      id: 'cks7w2d4z15832181mlqa2jv1s20',
      publishState: 'PUBLISHED',
      name: 'On The Rocks – Musik die bewegt: Heute: Clairo',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/on-the-rocks-musik-die-bewegt-heute-floating-points-pharoah-sanders-and-the-london-symphony-orchestrapromises',
    id: 'cksbj4r7b1341b0vevkler6ul',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-03-30T17:03:28.160Z',
      id: 'ckmw9rg6812427151nmgim35bqtr',
      publishState: 'PUBLISHED',
      name: 'On the rocks- Musik, die bewegt. Heute: Floating Points; Pharoah Sanders & The London Symphony Orchestra–PROMISES',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/on-the-rocks-musik-die-bewegt-heute-martyn-heyne-open-lines',
    id: 'cksbj4r791327b0ve5a9gfjd2',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-11-25T15:13:26.979Z',
      id: 'ckhxjshc305421gmqhn14ujhu',
      publishState: 'PUBLISHED',
      name: 'On the Rocks – Musik, die bewegt. Heute: Martyn Heyne - Open Lines',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/on-the-rocks-musik-die-bewegt-heute-skalpel-highlight',
    id: 'cksbj4r791321b0vek2bapl70',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-09-29T10:58:57.474Z',
      id: 'ckfnulnb6011718qt53jgkrb5',
      publishState: 'PUBLISHED',
      name: 'On the rocks – Musik, die bewegt. Heute: Skalpel – Highlight',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/southern-comfort-jelly-shots',
    id: 'cksbj4r7b1342b0ve7qbz66ei',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-04-05T19:47:28.916Z',
      id: 'ckn509hck3027531nmgb33qbr68',
      publishState: 'PUBLISHED',
      name: 'Southern Comfort Jelly shots! ',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value:
      '/the-easy-life/streuobstwiesen-die-geheimen-reservate-mitteleuropas',
    id: 'cksbj4r791324b0veod005rf6',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-11-02T20:44:53.148Z',
      id: 'ckh10i49o04011iop7j0tctyt',
      publishState: 'PUBLISHED',
      name: 'Streuobstwiesen: die geheimen Reservate Mitteleuropas',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: 'ckhkxkiby00001gmf3a1o7qy9',
    inheritedTheme: {
      id: 'ckhkxkiby00001gmf3a1o7qy9',
      title: 'Default flipbook',
    },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/video-portrait-urban-terrazzo',
    id: 'cksbj4r791326b0vej0ubxoft',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-11-25T14:58:35.766Z',
      id: 'ckhxj9do603331gmfxxddolx5',
      publishState: 'PUBLISHED',
      name: 'Video-Portrait: Urban Terrazzo',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/vinyl-die-renaissance-der-schallplatte',
    id: 'cksbj4r791323b0vewvle6yjk',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.845Z',
    content: {
      createdAt: '2020-09-29T10:58:25.839Z',
      id: 'ckfnukywf010818qt3wjx2j6w',
      publishState: 'PUBLISHED',
      name: 'Vinyl – die Renaissance der Schallplatte',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/vorhang-auf-fur-southern-comfort-black',
    id: 'cksbj4r7b1344b0vezmcczu9l',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-04-15T17:03:31.931Z',
      id: 'cknj4t5qz12180641mo19edw66ik',
      publishState: 'PUBLISHED',
      name: 'Vorhang auf für Southern Comfort Black!',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/the-easy-life/warum-machst-du-das',
    id: 'cksbj4r7b1343b0ve6gzi53gt',
    level: 2,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.847Z',
    content: {
      createdAt: '2021-04-15T13:46:41.435Z',
      id: 'cknixs0pn10530171mo1ws0qaua5',
      publishState: 'PUBLISHED',
      name: 'Warum machst du das?',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/unsere-produkte',
    id: 'cksbj4r781310b0vefb8hy8s6',
    level: 1,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.844Z',
    content: {
      createdAt: '2021-01-18T12:32:08.501Z',
      id: 'ckk2jt1dh003018p8k74wpkvm',
      publishState: 'PUBLISHED',
      name: 'Unsere Produkte',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/whisky',
    id: 'cksbj4r741271b0ve4ufher8v',
    level: 1,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.840Z',
    content: {
      createdAt: '2020-09-29T11:40:42.251Z',
      id: 'ckfnw3c0b013218qt4v25d6wd',
      publishState: 'DRAFT',
      name: 'Whisky',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
  {
    value: '/whiskygeschichte',
    id: 'cksbj4r741272b0vept57csd9',
    level: 1,
    redirect: null,
    createdAt: '2021-08-14T08:40:49.840Z',
    content: {
      createdAt: '2020-09-29T11:40:57.723Z',
      id: 'ckfnw3ny3013318qtiazoqlym',
      publishState: 'DRAFT',
      name: 'Whiskygeschichte',
      preferences: {},
      is404: false,
    },
    tags: [],
    languageId: null,
    themeId: null,
    inheritedTheme: { id: 'ckewixnrl004818q8hyzuclp0', title: 'Default' },
    inheritedLanguage: { id: 'scene-language-id-en', title: 'English' },
  },
]

export default function PlaygroundPage() {
  return (
    <>
      <div style={{ fontSize: 12 }}>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
          crossOrigin="anonymous"
        />

        <h1>Playground Page</h1>

        <DataTable
          itemsPerPage={5}
          initialSorting={{
            col: 'value',
            direction: 'asc',
          }}
          name="playground:table"
          data={testData.slice(0, 5)}
          cols={{
            id: { label: 'ID' },
            value: { label: 'Value', sortable: true },
            actions: {
              render({ item }) {
                return (
                  <>
                    <button onClick={() => console.log(item)}>
                      {item.content.name}
                    </button>
                  </>
                )
              },
            },
          }}
        />
      </div>
    </>
  )
}
